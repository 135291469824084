// Contentful
import { Entry } from 'contentful';

// Custom types
import { CPromoPeriodArticle } from 'src/types/contentful';
import BigCalendarEvent from 'src/types/bigCalendarEvent';
import { GeneralCalendarEntry } from 'src/types/contentful/generalCalendarEntryCollection';

/**
 * Converts an 'article summary' in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {GeneralCalendarEntry} entry - the Contentful article summary to convert
 * @return {ArticleSummary}
*/
export const articleToCalendarEventWithFilters = (entry: GeneralCalendarEntry, index: number): BigCalendarEvent => {
  const { startDate, endDate, entryTitle, article, colour } = entry;
  const calendarEvent: BigCalendarEvent = {
    id: index,
    start: new Date(Date.parse(startDate)),
    end: new Date(Date.parse(endDate)),
    allDay: true,
    title: entryTitle ?? '',
    colour,
    internalId: article.sys.id
  };
  return calendarEvent;
};

/**
 * Converts an 'article summary' in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {GeneralCalendarEntry[]} entries - the Contentful articles to convert
 * @return {ArticleSummary}
*/
export const articlesToCalendarEventsWithFilters = (entries: GeneralCalendarEntry[]): BigCalendarEvent[] => entries.map((e, idx) => articleToCalendarEventWithFilters(e, idx));

/**
 * Converts an 'article summary' in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulArticleSummary>} entry - the Contentful article summary to convert
 * @return {ArticleSummary}
*/
export const articleToCalendarEvent = (entry: Entry<CPromoPeriodArticle>, index: number): BigCalendarEvent => {
  const { fields } = entry;
  const calendarEvent: BigCalendarEvent = {
    id: index,
    start: new Date(Date.parse(fields.startDate)),
    end: new Date(Date.parse(fields.endDate)),
    allDay: true,
    title: fields.entryTitle ?? '',
    colour: fields.colour,
    internalId: fields.article.sys.id
  };
  return calendarEvent;
};

/**
 * Converts an 'article summary' in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulPromotionalPeriodArticle[]>[]} entries - the Contentful articles to convert
 * @return {ArticleSummary}
*/
export const articlesToCalendarEvents = (entries: Entry<CPromoPeriodArticle>[]): BigCalendarEvent[] => entries.map((e, idx) => articleToCalendarEvent(e, idx));
