import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      AppName: 'Parkland Corporation - The Gateway',
      Welcome: 'Welcome to the Parkland Gateway',
      OverviewPage: 'Overview',
      AccountSettingsPage: 'Account Settings',
      LoginPage: 'Login',
      PasswordResetPage: 'Password Reset',
      PasswordRecoveryPage: 'Password Recovery',
      SafetySecurityCompliancePage: 'Safety, Security and Compliance',
      SiteOperationsPage: 'Site Operations',
      FinancialPage: 'Financial',
      CustomerExperiencePage: 'Customer Experience',
      MarketingPage: 'Marketing',
      MerchandisingPage: 'Merchandising',
      TrainingPage: 'Training',
      FormsPage: 'Forms',
      HomePage: 'Home',
      FavouritesPage: 'Favourites',
      LinksPage: 'Links',
      ProfilePage: 'Profile',
      ReportsPage: 'Reports',
      AnnouncementsHeader: 'Announcements',
      NoAnnouncementsMessage: 'There are no announcements',
      CriticalUpdatesHeader: 'Critical Updates',
      NoCriticalUpdatesMessage: 'You have no critical or important updates',
      LatestUpdatesHeader: 'View Latest Updates',
      NoUpdatesInSelectedTimeframeMessage: 'There were no updates in the selected timeframe',
      LanguageTooltip: 'Language',
      SearchTooltip: 'Search',
      CheckTooltip: 'Checklist',
      HelpTooltip: 'Help',
      CategoriesNavSection: 'Categories',
      Categories: 'Categories',
      ManageNavSection: 'Manage',
      Account: 'Account',
      Checklist: 'Checklist',
      Logout: 'Logout',
      Search: 'Search',
      Language: 'Language',
      Help: 'Help',
      CriticalImportantUpdatePage: 'Critical Update',
      AnnouncementPage: 'Announcement',
      LatestUpdatesPage: 'Latest Updates',
      HappeningTodayMessage: 'Here\'s what\'s happening today',
      ArticleNotFound: 'Sorry, we couldn\'t find that article',
      LocalWeatherHeading: 'Local Weather',
      Favourite: 'Favourite',
      Unfavourite: 'Remove from Favourites',
      GoodMorning: 'Good Morning',
      GoodAfternoon: 'Good Afternoon',
      GoodEvening: 'Good Evening',
      ArticlesInCategoryNotFound: 'We couldn\'t find any articles in that category',
      AcknowledgeableArticlesInCategoryNotFound: 'We couldn\'t find any acknowledgeable articles in that category',
      SubcategoriesInCategoryNotFound: 'We couldn\'t find more categories in that category',
      ArticlesInSubcategoryNotFound: 'We couldn\'t find any articles in that category and subcategory',
      MoreCategoriesIn: 'More Categories in ',
      safetysecuritycompliance: 'Safety, Security and Compliance',
      siteoperations: 'Site Operations',
      financial: 'Financial',
      customerexperience: 'Customer Experience',
      marketing: 'Marketing',
      merchandising: 'Merchandising',
      training: 'Training',
      PlanogramsPage: 'Planograms',
      TMDashboardPage: 'Territory manager Dashboard',
      HelpPage: 'Help',
      FAQPage: 'Frequently Asked Questions',
      ContactDirectoryPage: 'Contact Directory',
      TermAndConditionsPage: 'Terms and Condition',
      Submit: 'Submit',
      Other: 'Other',
      SaveChanges: 'Save Changes',
      FirstName: 'First Name',
      LastName: 'Last Name',
      Email: 'Email',
      City: 'City',
      Timezone: 'Timezone',
      Perferences: 'Preferences',
      UserSettingsSaved: 'Your settings have been saved',
      UnableToSaveSettings: 'We were unable to save your settings',
      TitleTableHeader: 'Title',
      DateModifedTableheader: 'Date Modifed',
      FavouriteTableHeader: 'Favourite',
      ShelfSizeTableHeader: 'Shelf Size',
      DownloadTableHeader: 'Download',
      Tags: 'Tags',
      ArticleFavourited: 'Article favourited',
      ArticleUnfavourited: 'Article unfavourited',
      CouldNotUpdateFavourite: 'Sorry, we could not update the favourite',
      ArticleAcknowledged: 'Thank you for acknlowedging this article',
      CouldNotUpdateArticleAcknowledgedStatus: 'Sorry, we could not save your acknolwedgement of this article',
      HelpMessageSent: 'Your message was sent',
      CouldNotSendHelpMessage: 'Sorry, we couldn\'t send your message',
      UserSettings: 'User Settings',
      TellUsMore: 'Tell us more about yourself so that we can set things up for you',
      PreferredStation: 'Preferred Station',
      NoFavouritedArticlesFound: 'You haven\'t favourited any articles',
      NoFavouritedPlanoGramsFound: 'You haven\'t favourited any planograms',
      Select: 'Select',
      LookingForSomething: 'Looking for Something?',
      SearchInstructions: 'Just type in whatever you are looking for and we will try to find it for you.',
      WhoReadWhatArticles: 'Who Read What Articles?',
      WhoReadWhatArticlesReportTitle: 'Who Read What Articles?',
      WhoReadWhatArticlesReportSummary: 'Drill down to view different performance metrics on who read which articles',
      WhoReadWhatArticlesReportRoute: 'whoreadwhatarticles',
      WhoAcknowledgedWhatArticles: 'Who Acknowledged What Articles?',
      WhoAcknowledgedWhatArticlesReportTitle: 'Who Acknowledged What Articles?',
      WhoAcknowledgedWhatArticlesReportSummary: 'Drill down to view different performance metrics on who acknowledged which articles',
      WhoAcknowledgedWhatArticlesReportRoute: 'whoacknowledgedwhatarticles',
      WeCouldNotFindArticlesFor: 'We couldn\'t find any articles for search',
      WeCouldNotFindPlanogramsFor: 'We couldn\'t find any planograms for search',
      Found: 'Found',
      ResultsForSearch: 'result(s) for search',
      StationPicker: 'Select a station',
      StationChanged: 'Your selected station was changed. Hold on while we refresh the your view',
      FilterPlanoGramsByTag: 'Filter Planograms by tag',
      HelpNeed: 'What do you need help with?',
      helpSelectTopic: 'Select a topic',
      ViewAllArticles: 'View All Articles',
      CurrentlySelectedStation: 'Current station',
      ReportStatistics: 'Report Statistics',
      NoArticleSelected: 'No article selected',
      ViewingArticle: 'Viewing Article',
      ViewAllCategories: 'View All Categories',
      View: 'View',
      OtherStationsFound: 'other station(s) found',
      StationsFound: 'station(s) found',
      NoPlanogramsFound: 'Sorry, there are currently no Planograms for your region, operating model and brand',
      NoContactsFound: 'Sorry, there are currently no contacts',
      NoFAQsFound: 'Sorry, there are currently no frequently asked questions',
      NoFormsFound: 'Sorry, there are currently no forms',
      NoLinksFound: 'Sorry, there are currently no links',
      NoStationsAssigned: 'You have no stations assigned',
      ViewPlanograms: 'View Planograms',
      PlanogramCategories: 'Planogram Categories',
      CouldNotGetFavourites: 'Sorry, we could not retrieve your favourite articles',
      CouldNotGetArticle: 'Sorry, we could not retrieve that article',
      CouldNotGetArticleCategory: 'Sorry, we could not retrieve articles for that category',
      CouldNotGetForms: 'Sorry, we could not get the list of forms',
      CouldNotGetLinks: 'Sorry, we could not get the list of links',
      CouldNotGetPlanograms: 'Sorry, we could not get the list of planograms',
      CouldNotGetContacts: 'Sorry, we could not get the list of contacts',
      CouldNotGetFAQs: 'Sorry, we could not get the list of frequently asked questions',
      CouldNotGetHelpTopics: 'Sorry, we could not get the list of help topics',
      IHaveReadAndUnderstoodArticle: 'I have read and understood this article',
      YouHaveReadAndUnderstoodArticle: 'You have read and understood this article',
      Attachments: 'Attachments',
      Brand: 'Brand',
      Site: 'Site',
      Address: 'Address',
      CantSeeTheStationsLookingFor: 'Can\'t see the station(s) you are looking for?',
      RefreshStations: 'Refresh Stations',
      CouldNotGetStations: 'Sorry, we could not get the list of stations',
      Admin: 'Admin',
      TerritoryManager: 'Territory Manager',
      SuperAdmin: 'Super Admin',
      Articles: 'Articles',
      Planograms: 'Planograms',
      NoDownloadsForPlanogram: 'There is no download for this planogram',
      PlanogramFavourited: 'Planogram Favourited',
      PlanogramUnfavourited: 'Planogram Unfavourited',
      General: 'General',
      DetailRequestSpecific: 'Detail Request (Please be specific)',
      EmailMeACopy: 'Email me a copy',
      ReturnToDefaultView: 'Return to Admin View',
      YouCanNowSeeAllContent: 'You will now be able to see all content. Hold one while we refresh your view',
      StationsRefreshed: 'Stations refreshed',
      StationPickerOptions: 'Options',
      OperatingModel: 'Operating Model',
      Category: 'Category',
      AdminDashboard: 'Admin Dashboard',
      ManageUsers: 'Manage Users',
      ManageStations: 'Manage Stations',
      InternalServerError: '500: Internal Server Error',
      GenericIntenralServerMessage: 'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
      UserTableHeader: 'User',
      EmailTableHeader: 'Email',
      SuperTableHeader: 'Super',
      AdminTableHeader: 'Admin',
      TMTableHeader: 'TM',
      LocalWeather: 'Local Weather',
      CouldNotGetLocalWeather: 'We cannot display your local weather. Turn on location for this site to display local weather.',
      BrowserDoesNotSupportVideo: 'Your browser does not support video.',
      StationName: 'Station',
      Date: 'Date',
      DownloadReport: 'Download Report',
      StationsWhoReadArticleReport: 'stations-who-read-article',
      StationsWhoDidntReadArticleReport: 'stations-who-didnt-read-article',
      StationsWhoAcknowledgedArticleReport: 'stations-who-acknowledged-article',
      StationsWhoDidntAcknowledgeArticleReport: 'stations-who-didnt-acknowledge-article',
      SiteID: 'Site ID',
      AllStations: 'All Stations',
      SelectedStations: 'Selected Stations',
      Filter: 'Filter',
      MDMAssignedStations: 'MDM Assigned Stations',
      UserNotInitialised: 'User not initialised',
      Stations: 'stations',
      Selected: 'selected',
      ClearSelection: 'clear selection',
      Clear: 'Clear',
      Cancel: 'Cancel',
      Download: 'Download',
      About: 'About',
      AppVersion: 'Version',
      AppEnvironment: 'Environment',
      ShelfSize: 'Shelf Size',
      UpdatedDate: 'Updated Date',
      None: 'None',
      SiteNotConfiguredCorrectly: 'Your selected site’s brand/region/operating model has not been configured correctly. Please contact your Territory Manager.',
      NoStationSelected: 'You haven\'t selected a station. Please select a station (via the gasoline station icon in the navigation bar) or contact your Territory Manager to assign you a station(s).',
      ReasonNoSelectedStation: 'No station selected',
      ReasonEmptyRegion: 'Empty region',
      ReasonIllCnfiguredRegion: 'Wrongly configured region',
      ReasonEmptyOperatingModel: 'Empty operating model',
      ReasonIllConfiguredOperatingModel: 'Wrongly configured operating model',
      ReasonEmptyBrand: 'Empty brand',
      ReasonIllConfiguredBrand: 'Wrongly configured brand',
      ListOfIllConfiguredReasons: 'Here are a list of reasons why we cannot show you content for the selected site',
      ArticlesIn: 'Articles in',
      NoCategorySelected: 'No category selected',
      CouldNotFindStationsbasedOnSearch: 'Sorry, we couldn\'t find any stations based on your search. Please check  the spelling of the station or try Options -> Refresh Stations if the station you are looking for isn\'t appearing',
      FilterArticlesByTitle: 'Filter articles by title',
      LoadingRefreshingStations: 'Loading/Refreshing Stations ...',
      DealerCommissionReport: 'Dealer Station Commission Report',
      DealerCommissionReportPage: 'Dealer Commission',
      FuelPriceReport: 'Fuel Price Report',
      FuelPriceReportPage: 'Fuel Price',
      EndDtMustbeEqualOrGreaterThanStartDt: 'End date must be the same day or or later than start date',
      Period: 'Period',
      Product: 'Product',
      SSPrice: 'SS Price',
      FSPrice: 'FS Price',
      SSmargin: 'SS Margin',
      FSMargin: 'FS Margin',
      ExportToExcel: 'Export to Excel',
      MysteryShopper: 'Third Party Shops',
      ScoreOverTime: 'Score Over Time',
      ScoreComparison: 'Score Comparison',
      ScoreDetails: 'Score Details',
      Dashboard: 'Dashboard',
      Section: 'Section',
      PreviousScore: 'Previous Score',
      CurrentScore: 'Current Score',
      Difference: 'Difference',
      BU: 'BU',
      DocumentDate: 'Document Date',
      Province: 'Province',
      FuelBrand: 'Fuel Brand',
      ROM: 'ROM',
      TM: 'TM',
      OverallScore: 'Overall Score',
      InteriorScore: 'Interior Score',
      ExteriorScore: 'Exterior Score',
      WashroomScore: 'Washroom Score',
      CustomerService: 'Customer Service',
      LoyaltyAsk: 'Loyalty Ask',
      LoyaltyOffer: 'Loyalty offer',
      UpsellProduct: 'Upsell Product',
      Overall: 'Overall',
      InteriorCleanliness: 'Interior Cleanliness',
      ExteriorCleanliness: 'Exterior Cleanliness',
      WashroomCleanliness: 'Washroom Cleanliness',
      RecordsReceived: 'Records Received',
      AverageScore: 'Average Score',
      AverageScorePercentage: 'Average Score',
      NumberOfMysteryShops: '# Mystery Shops',
      SearchColumn: 'Search Column',
      NoDataForSelectedStations: 'There is no data for the selected station(s)',
      MysteryShopperReportFileName: 'Mystery Shopper Report.csv',
      OTRDealer: 'OTR Dealer',
      Station: 'Station',
      WeatherNotAvailable: 'Sorry, weather is not available at this time',
      ViewReport: 'View Report',
      StartDate: 'Start Date',
      EndDate: 'End Date',
      RiskSharing: 'Risk Sharing',
      Loading: 'Loading ...',
      SessionHasExpired: 'Your session has expired. Hold tight while we refresh your information.',
      MysteryShopAdminNoStationSelected: 'You are currently logged in as an admin and you have no site selected. Please select a site via the station picker in the top navigation bar to see maytery shopper data for that site.',
      LoadingRiskSharing: 'loading station risk sharing ...',
      Updates7Days: '7 Days',
      Updates15Days: '15 Days',
      Updates30Days: '30 Days',
      DateRange: 'Date Range',
      SearchDateRange: 'Search Date Range',
      UserActivityReport: 'User Activity Report',
      ActivityType: 'Activity Type',
      UserActivitysReportSummary: 'View different activities that users performed in the system by date',
      UserActivityReportRoute: 'useractivityreport',
      CouldNotFindArticleWithTag: 'Sorry, we couldn\'t find any articles tagged with ',
      ArticlesWithTag: 'Articles with Tag',
      NoDealerCommissionResultsFound: 'We couldn\'t find any records for the selected station in the selected date range.',
      BulkUpdateContent: 'Bulk Update Content',
      BulkUpdate: 'Bulk Update',
      PageName: 'Page Name',
      Metadata: 'Metadata',
      CarwashDashboard: 'Carwash Dashboard',
      PromotionalPeriods: 'Promotional Periods',
      CarWash: 'Car Wash',
      ShareArticle: 'Share Article',
      ViewUserActivityMetadata: 'View Metadata',
      NoUserActivitiesFound: 'We couldn\'t find any user activity in the time period selected',
      ViewingNumberOfStations: 'Currently Viewing Number of Stations',
      MayNotBeDataForAllStations: 'There may not be data for all stations',
      CouldNotSendEmail: 'We could not send the email',
      ArticleSharedSuccessfully: 'The article was shared successfully',
      SharedArticleWithYou: 'shared an article on The Gateway with you',
      HelpEmailSubject: 'New Help Request from Parkland Gateway',
      ToName: 'To Name',
      ToEmail: 'to Email',
      EmailMessage: 'Message',
      Subject: 'Subject',
      NoStationFound: 'no valid station found',
      PageTitle401: 'Authorization Required',
      ErrorSummary401: '401: Authorization required',
      ErrorDescription401: 'You are not authorized to view the requested page. Please choose another page from the navigation menus.',
      SVGAltDescription401: 'Authorization Required',
      PageTitle404: 'Page Not Found',
      ErrorSummary404: '404: The page you are looking for isn’t here',
      ErrorDescription404: 'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
      SVGAltDescription404: 'Page Not Found',
      PageTitle500: 'Server Error',
      SVGAltDescription500: 'Server Error',
      ArticleUnauthorizedPageTitle: 'Article Not Authorized',
      ArticleUnauthorizedErrorSummary: 'You aren\'t authorized to see this article',
      ArticleUnauthorizedErrorDescription: 'The requested article is for a different brand, region or operating model than the station you currently have selected.',
      ArticleUnauthorizedSVGAltDescription: 'Article Not Authorized',
      BackToHome: 'Back to Home',
      ThisPageIsUnderConstruction: 'This page is under construction',
      FuelRunoutReportTitle: 'Fuel Runout Report',
      FuelRunoutReportSummary: 'Fuel Runout Report Summary to come',
      FuelRunoutReportRoute: 'fuelrunoutreport',
      DowngradeRunoutReportTitle: 'Downgrade and Runout Report',
      DowngradeRunoutReportSummary: 'Downgrade Runout Report Summary to come',
      DowngradeRunoutReportRoute: 'downgraderunoutreport',
      ManageTeam: 'Manage Team',
      DownloadPDFReport: 'Download PDF Report',
      PDFReportLoading: 'PDF Report Loading',
      SelectValidDateIn2020OrAfter: 'Please select a valid start and end date in 2020 or later',
      SubmitServiceTicket: 'Submit Service Ticket',
      CouldNotSendServiceTicket: 'We could not send the service ticket',
      ServiceTicketSentSuccessfully: 'The service ticket was created successfully',
      DropOrSelectFiles: 'Drag and drop some files here, or click to select files',
      SelectedFiles: 'Selected Files',
      OpenFileDialog: 'Open File Dialog',
      FileName: 'File Name',
      PlanogramName: 'Planogram Name',
      RecordCount: 'Record Count',
      SearchTerm: 'Search Term',
      HelpTopic: 'Help Topic',
      ArticleName: 'Article Name',
      ArticleID: 'Article ID',
      ReportName: 'Report Name',
      Error: 'Error',
      Phone: 'Phone',
      AddNewUser: 'Add New User',
      AddUser: 'Add User',
      UpdateUser: 'Update User',
      DeleteUser: 'Delete User',
      Delete: 'Delete',
      PleaseSelectAStation: 'Please select a station in order to manage that station\'s team',
      TheUserWasSuccessfullyAdded: 'The user was successfully added',
      SorryWeCouldNotAddTheNewUser: 'Sorry, we could not add the new user',
      TheUserWasSuccessfullyUpdated: 'The user was successfully updated',
      SorryWeCouldNotUpdateTheUser: 'Sorry, we could not update the user',
      AreYouSureYouWantToDeleteThisUserFromTheTeam: 'Are you sure uou want to delete this user from the team?',
      AreYouSureYouWantToDisableThisUserInTheTeam: 'Are you sure you want to disable this user?',
      AreYouSureYouWantToEnableThisUserInTheTeam: 'Are you sure you want to enable this user?',
      TheUserWasSuccessfullyDeleted: 'The user was successfully deleted',
      SorryWeCouldNotDeleteTheUser: 'Sorry, we could not delete the user',
      TheUserWasSuccessfullyDisabled: 'The user was successfully disabled',
      SorryWeCouldNotDisableTheUser: 'Sorry, we could not disable the user',
      TheUserWasSuccessfullyEnabled: 'The user was successfully enabled',
      SorryWeCouldNotEnableTheUser: 'Sorry, we could not enable the user',
      FromEmail: 'From Email',
      FilesSelected: 'files selected',
      SelectFiles: 'Select Files',
      FileBytes: 'bytes',
      NoFilesSelected: 'no files selected',
      EventsCalendar: 'Events Calendar',
      SubmitAServiceTicket: 'Submit a service ticket now',
      DoYouNeedToUpdateTeamUser: 'Do you need to update any user(s) on your team? Please submit a service ticket specifying all users and fields that need updating.',
      NoHSEMoments: 'There are no Health, Safety and Environment moments',
      NoOTRArticles: 'There are no OTR Articles',
      HSEMoment: 'HSE Moment',
      Only5AttachmentsAllowed: 'Only 5 attachments are allowed at one time.',
      SorryWeCouldNotDownloadThePDFReport: 'Sorry, we could not download the PDF report',
      CouldNotGetStationConfiguration: 'We could not get the station configuration',
      CouldNotGetPDFReport: 'could not load pdf report',
      OTRHub: 'On The Run',
      OTRPromoOptions: 'Promotional Options',
      OTRPromoOptionA: 'A',
      OTRPromoOptionB: 'B',
      OTRPromoOptionC: 'C',
      OTRPromoOptionD: 'D',
      UpdatePromoCode: 'Update Promo Code',
      SiteHasNotMadeOTRSelection: 'There is no OTR promotion code slected for this station for the current promotion',
      CurrentOTRSelectionOn: 'Current promition code selection made on',
      CurrentlySelectedStationIsNotOTR: 'You are not authorized to see this page as the currently selected station is not an OTR Dealer',
      CurrentlySelectedStationDoesNotHaveCarWash: 'You are not authorized to see this page as the currently selected station does not have a car wash',
      PromoCodeUpdatedSuccessfully: 'Your promo code was for this period was updated successfully',
      DownloadMysteryShopPDF: 'Download Mystery Shop PDF',
      AgeRestrictedShopsCount: 'Age restricted shops count',
      MysteryShopPassRate: 'pass rate %',
      ShopType: 'Shop Type',
      ThereAreCurrentlyNoCarwashArticles: 'There are currently no car wash articles',
      CarWashArticles: 'Car Wash Articles',
      MysteryShop: 'Mystery Shop',
      AgeRestricted: 'Age Restricted',
      TMAudit: 'TM Audit',
      OnlyImageFileTypesCurrentlySupported: 'Currently only PNG, JPG and JPEG image types are supported for attachments',
      ViewFuelPrice: 'View Fuel Price',
      OnTheRun: 'OTR Hub',
      AverageForMysteryShops: 'Mystery Shops Average',
      AverageForAgeRestrictedShops: 'Age Restricted Shops Average',
      PriceChangeType: 'Price Change Type',
      EffectiveDate: 'Effective Date',
      FullPrice: 'Fuel Price',
      FuelType: 'Fuel Type',
      CouldNotLoadSearchResults: 'Sorry we could not load any search results at this time. Please contact your administrator if this persists.',
      TermsAndConditionsPage: 'Terms and Conditions',
      FuelRunoutReportPage: 'Fuel Runout Report',
      ReportAFuelRunOut: 'Report a Fuel Runout',
      DowngradeRunoutReportPage: 'Downgrade & Runout Report',
      EmailReportSentSuccessful: 'Email Report sent Successfully',
      UnableToEmailReport: 'Unable to Send Fuel Runout Report!',
      BuySellModels: 'BUY/SELL MODELS',
      TheReportWillGoToYourTM: 'This report will go to you TM. If more information is required, your TM will reach out to you.',
      TheSurveyWillTakeApproximately: 'The survey will take approximately 4 minutes to complete.',
      PleaseCompleteAllAsApplicable: 'PLEASE COMPLETE ALL AS APPLICABLE REVIEW DATA VALIDATION ON THE RIGHT. ANY FORMS WITH RED WILL BE RETURNED.',
      DigitJDEStation: 'Digit JDE Station Number (BU Number)/No SAP [4XXXX] or [5XXXX]',
      EmailAddress: 'Email Address',
      RunoutStartDate: 'Runout Start Date',
      RunoutStartTime: 'Runout Start Time',
      RunoutEndDate: 'Runout End Date',
      RunoutEndTime: 'Runout End Time',
      ProductOut: 'Product Out',
      DidDowngradeOccur: 'Did downgrade occur?',
      NumberOfLitresDowngraded: 'Number of litres downgraded',
      CommentsIfRunoutDowngrade: 'Comments (note here if runout/downgrade is not delivery related. Example mechanical or systems issues).',
      InternalDocumentationPage: 'Internal Documentation',
      NoArticlesFound: 'Sorry, there are currently no articles to view here',
      NoCategoriesFound: 'No categories were found',
      DisableUser: 'Disable User',
      EnableUser: 'Enable User',
      Notifications: 'Notifications',
      Refresh: 'Refresh',
      Status: 'Status',
      OTROptions: 'OTR Options',
      ThereAreNoNotifications: 'You currently don\'t have any notifications',
      ThereAreNewArticlesForYourStation: 'There are new articles to read for your station',
      OTRArticle: 'OTR Article',
      LatestOTRContent: 'Latest OTR Content',
      RefreshNotifications: 'Refresh Notifications'
    }
  },
  fr: {
    translation: {
      AppName: 'Parkland Corporation - La Passerelle',
      Welcome: 'Bienvenue à la Passerelle Parkland',
      OverviewPage: 'Aperçu',
      AccountSettingsPage: 'Paramètres du Compte',
      LoginPage: 'Connexion',
      PasswordResetPage: 'Réinitialisation du mot de Passe',
      PasswordRecoveryPage: 'Récupération de mot de Passe',
      SafetySecurityCompliancePage: 'Sûreté, Sécurité et Conformité',
      SiteOperationsPage: 'Opérations du Site',
      FinancialPage: 'Financier',
      CustomerExperiencePage: 'Expérience Client',
      MarketingPage: 'Commercialisation',
      MerchandisingPage: 'Marchandisage',
      TrainingPage: 'Formation',
      FormsPage: 'Formes',
      HomePage: 'Domicile',
      FavouritesPage: 'Favoris',
      LinksPage: 'Liens',
      ProfilePage: 'Profil',
      ReportsPage: 'Rapports',
      AnnouncementsHeader: 'Annonces',
      NoAnnouncementsMessage: 'Il n\'y a pas d\'Annonces',
      CriticalUpdatesHeader: 'Mises à Jour Critiques',
      NoCriticalUpdatesMessage: 'Vous n\'avez aucune mise à jour critique ou importante',
      LatestUpdatesHeader: 'Afficher les dernières mises à jour',
      NoUpdatesInSelectedTimeframeMessage: 'Il n\'y a eu aucune mise à jour dans la période sélectionnée',
      LanguageTooltip: 'Langue',
      SearchTooltip: 'Rechercher',
      CheckTooltip: 'Liste de Contrôle',
      HelpTooltip: 'Aider',
      CategoriesNavSection: 'Catégories',
      Categories: 'Catégories',
      ManageNavSection: 'Gestion',
      Account: 'Compte',
      Checklist: 'Liste de Contrôle',
      Logout: 'Se Déconnecter',
      Search: 'Rechercher',
      Language: 'Langue',
      Help: 'Aider',
      CriticalImportantUpdatePage: 'Mise à Jour Critique',
      AnnouncementPage: 'Annonce',
      LatestUpdatesPage: 'Dernières mises à jour',
      HappeningTodayMessage: 'Voici ce qui se passe aujourd\'hui',
      ArticleNotFound: 'Désolé, nous n\'avons pas pu trouver cet article',
      LocalWeatherHeading: 'La Météo Locale',
      Favourite: 'Préférée',
      Unfavourite: 'Supprimer des Favoris',
      GoodMorning: 'Bonjour',
      GoodAfternoon: 'Bonne Après-Midi',
      GoodEvening: 'Bonne Soirée',
      ArticlesInCategoryNotFound: 'Nous n\'avons trouvé aucun article dans cette catégorie',
      AcknowledgeableArticlesInCategoryNotFound: 'Nous n\'avons trouvé aucun article reconnu dans cette catégorie',
      SubcategoriesInCategoryNotFound: 'Nous n\'avons pas pu trouver plus de catégories dans cette catégorie',
      ArticlesInSubcategoryNotFound: 'Nous n\'avons trouvé aucun article dans cette catégorie et sous-catégorie',
      MoreCategoriesIn: 'Plus de Catégories Dans ',
      safetysecuritycompliance: 'Sûreté, Sécurité et Conformité',
      siteoperations: 'Opérations du Site',
      financial: 'Financier',
      customerexperience: 'Expérience Client',
      marketing: 'Commercialisation',
      merchandising: 'Marchandisage',
      training: 'Formation',
      PlanogramsPage: 'Planogrammes',
      TMDashboardPage: 'Tableau de bord du Gestionnaire de Territoire',
      HelpPage: 'Aider',
      FAQPage: 'Questions Fréquemment Posées',
      TermAndConditionsPage: 'Terms and Condition',
      ContactDirectoryPage: 'Annuaire des Contacts',
      Submit: 'Soumettre',
      Other: 'Autre',
      SaveChanges: 'Sauvegarder les Modifications',
      FirstName: 'Prénom',
      LastName: 'Nom de Famille',
      Email: 'E-mail',
      City: 'Ville',
      Timezone: 'Fuseau Horaire',
      Perferences: 'Préférence',
      UserSettingsSaved: 'Vos réglages ont été sauvegardés',
      UnableToSaveSettings: 'Nous n\'avons pas pu enregistrer vos paramètres',
      TitleTableHeader: 'Titre',
      DateModifedTableheader: 'Date modifiée',
      FavouriteTableHeader: 'Préférée',
      ShelfSizeTableHeader: 'Taille de l\'étagère',
      DownloadTableHeader: 'Télécharger',
      Tags: 'Mots clés',
      ArticleFavourited: 'Article favori',
      ArticleUnfavourited: 'Article défavorisé',
      CouldNotUpdateFavourite: 'Désolé, nous n\'avons pas pu mettre à jour le favori',
      ArticleAcknowledged: 'Merci d\'avoir reconnu cet article',
      CouldNotUpdateArticleAcknowledgedStatus: 'Désolé, nous n\'avons pas pu enregistrer votre reconnaissance de cet article',
      HelpMessageSent: 'Votre message a été envoyé',
      CouldNotSendHelpMessage: 'Désolé, nous n\'avons pas pu envoyer votre message',
      UserSettings: 'Paramètres Utilisateur',
      TellUsMore: 'Parlez-nous de vous afin que nous puissions organiser les choses pour vous',
      PreferredStation: 'Gare Préférée',
      NoFavouritedArticlesFound: 'Vous n\'avez ajouté aucun article à vos favoris',
      NoFavouritedPlanoGramsFound: 'Vous n\'avez mis aucun planogramme en favori',
      Select: 'Sélectionnez',
      LookingForSomething: 'Vous cherchez quelque chose?',
      SearchInstructions: 'Tapez simplement ce que vous cherchez et nous essaierons de le trouver pour vous.',
      WhoReadWhatArticles: 'Qui a lu Quels Articles?',
      WhoReadWhatArticlesReportTitle: 'Qui a lu Quels Articles?',
      WhoReadWhatArticlesReportSummary: 'Explorez pour afficher différentes mesures de performance sur qui a lu quels articles',
      WhoReadWhatArticlesReportRoute: 'quialuquelsarticles',
      WhoAcknowledgedWhatArticles: 'Qui a reconnu Quels Articles?',
      WhoAcknowledgedWhatArticlesReportTitle: 'Qui a reconnu quels articles',
      WhoAcknowledgedWhatArticlesReportSummary: 'Explorez pour afficher différentes mesures de performance sur qui a reconnu quels articles',
      WhoAcknowledgedWhatArticlesReportRoute: 'quiareconnuquelsarticles',
      WeCouldNotFindArticlesFor: 'Nous n\'avons trouvé aucun article pour la recherche',
      WeCouldNotFindPlanogramsFor: 'Nous n\'avons trouvé aucun planogramme pour la recherche',
      Found: 'Trouvée',
      ResultsForSearch: 'Résultat(s) de la Recherche',
      NoContactsFound: 'Désolé, il n\'y a actuellement aucun contact',
      NoFAQsFound: 'Désolé, il n\'y a actuellement aucune foire aux questions',
      NoFormsFound: 'Désolé, il n\'y a actuellement aucun formulaire',
      NoLinksFound: 'Désolé, il n\'y a actuellement aucun lien',
      StationPicker: 'Sélectionnez Station',
      StationChanged: 'Votre station sélectionnée a été modifiée, Attendez pendant que nous actualisons votre vue',
      FilterPlanoGramsByTag: 'Filtrer les planogrammes par tag',
      NoPlanogramsFound: 'Désolé, il n\'y a actuellement aucun planogramme pour votre région, votre modèle d\'exploitation et votre marque',
      HelpNeed: 'Avec quoi as tu besoin d\'aide?',
      helpSelectTopic: 'Choisissez un sujet',
      ViewAllArticles: 'Voir tous les articles',
      CurrentlySelectedStation: 'Gare actuelle',
      ReportStatistics: 'Statistiques du rapport',
      NoArticleSelected: 'Aucun article sélectionné',
      ViewingArticle: 'Affichage de l\'article',
      ViewAllCategories: 'Voir toutes les Catégories',
      View: 'Voir',
      OtherStationsFound: 'Autres Stations Trouvées',
      StationsFound: 'Stations Trouvées',
      NoStationsAssigned: 'Vous n\'avez aucune station assignée',
      ViewPlanograms: 'Voir les Planogrammes',
      PlanogramCategories: 'Catégories de Planogrammes',
      CouldNotGetFavourites: 'Désolé, nous n\'avons pas pu récupérer vos articles favoris',
      CouldNotGetArticle: 'Désolé, nous n\'avons pas pu récupérer cet article',
      CouldNotGetArticleCategory: 'Désolé, nous n\'avons pas pu récupérer les articles pour cette catégorie',
      CouldNotGetForms: 'Désolé, nous n\'avons pas pu obtenir la liste des formulaires',
      CouldNotGetLinks: 'Désolé, nous n\'avons pas pu obtenir la liste des liens',
      CouldNotGetPlanograms: 'Désolé, nous n\'avons pas pu obtenir la liste des planogrammes',
      CouldNotGetContacts: 'Désolé, nous n\'avons pas pu obtenir la liste des contacts',
      CouldNotGetFAQs: 'Désolé, nous n\'avons pas pu obtenir la liste des questions fréquemment posées',
      CouldNotGetHelpTopics: 'Désolé, nous n\'avons pas pu obtenir la liste des rubriques d\'aide',
      IHaveReadAndUnderstoodArticle: 'J\'ai lu et compris cet article',
      YouHaveReadAndUnderstoodArticle: 'Vous avez lu et compris cet article',
      Attachments: 'Pièces Jointes',
      Brand: 'Marque',
      Site: 'Site',
      Address: 'Adresse',
      CantSeeTheStationsLookingFor: 'Vous ne voyez pas la ou les stations que vous recherchez ?',
      RefreshStations: 'Actualiser Stations',
      CouldNotGetStations: 'Désolé, nous n\'avons pas pu obtenir la liste des stations',
      Admin: 'Administrateur',
      TerritoryManager: 'Manager de Territoire',
      SuperAdmin: 'Super Administrateur',
      Articles: 'Des Articles',
      Planograms: 'Planogramme',
      PlanogramFavourited: 'Planogramme Favoris',
      PlanogramUnfavourited: 'Planogramme Défavorisé',
      General: 'Général',
      DetailRequestSpecific: 'Demande de Détail (Veuillez être précis)',
      EmailMeACopy: 'Envoyez-moi une copie par e-mail',
      ReturnToDefaultView: 'Revenir à la vue Administrateur',
      YouCanNowSeeAllContent: 'Vous pourrez désormais voir tout le contenu. Maintenez-en un pendant que nous actualisons votre vue',
      StationsRefreshed: 'Stations rafraîchies',
      StationPickerOptions: 'Options',
      OperatingModel: 'Modèle de Fonctionnement',
      Category: 'Catégorie',
      AdminDashboard: 'Tableau de bord Administrateur',
      ManageUsers: 'Gérer les utilisateurs',
      ManageStations: 'Gérer les stations',
      InternalServerError: '500 : Erreur de serveur interne',
      GenericIntenralServerMessage: 'Soit vous avez essayé un itinéraire ombragé, soit vous êtes venu ici par erreur. Quoi qu\'il en soit, essayez d\'utiliser la navigation.',
      UserTableHeader: 'Utilisateur',
      EmailTableHeader: 'E-mail',
      SuperTableHeader: 'Super',
      AdminTableHeader: 'Administrateur',
      TMTableHeader: 'TM',
      LocalWeather: 'La Météo Locale',
      CouldNotGetLocalWeather: 'Nous ne pouvons pas afficher votre météo locale. Activer la localisation pour que ce site affiche la météo locale.',
      BrowserDoesNotSupportVideo: 'Votre navigateur ne prend pas en charge la vidéo',
      StationName: 'Gare',
      Date: 'Date',
      DownloadReport: 'Télécharger le Rapport',
      StationsWhoReadArticleReport: 'stations-qui-lisent-article',
      StationsWhoDidntReadArticleReport: 'stations-qui-nont-pas-lu-larticle',
      StationsWhoAcknowledgedArticleReport: 'stations-qui-a-reconnu-article',
      StationsWhoDidntAcknowledgeArticleReport: 'les stations-qui-nont pas-accusé-a-reconnaissance-article',
      SiteID: 'ID du Site',
      AllStations: 'Toutes les Stations',
      SelectedStations: 'Stations Sélectionnées',
      Filter: 'Filtre',
      MDMAssignedStations: 'Stations Affectées MDM',
      UserNotInitialised: 'Utilisateur non initialisé',
      Stations: 'stations',
      Selected: 'choisi',
      ClearSelection: 'vider la selection',
      Clear: 'Vider',
      Cancel: 'Annuler',
      Download: 'Télécharger',
      About: 'À Propos',
      AppVersion: 'Version',
      AppEnvironment: 'Environnement',
      ShelfSize: 'Taille de l\'étagère',
      UpdatedDate: 'Date de mise à jour',
      None: 'Rien',
      SiteNotConfiguredCorrectly: 'La marque/la région/le modèle d\'exploitation de votre site sélectionné n\'ont pas été configurés correctement. Veuillez contacter votre gestionnaire de territoire.',
      NoStationSelected: 'Vous n\'avez pas sélectionné de station. Veuillez sélectionner une station (via l\'icône de station-service dans la barre de navigation) ou contactez votre gestionnaire de territoire pour vous attribuer une ou des stations.',
      ListOfIllConfiguredReasons: 'Voici une liste des raisons pour lesquelles nous ne pouvons pas vous montrer le contenu du site sélectionné',
      ArticlesIn: 'Articles dans',
      NoCategorySelected: 'Aucune catégorie sélectionnée',
      CouldNotFindStationsbasedOnSearch: 'Désolé, nous n\'avons trouvé aucune station en fonction de votre recherche. Veuillez vérifier l\'orthographe de la station ou essayez Options -> Rafraîchir les stations si la station que vous recherchez n\'apparaît pas',
      FilterArticlesByTitle: 'Filtrer les articles par titre',
      LoadingRefreshingStations: 'Stations de chargement/rafraîchissement...',
      DealerCommissionReport: 'Rapport de commission de la station de concessionnaires',
      DealerCommissionReportPage: 'Commission du concessionnaire',
      FuelPriceReport: 'Rapport du Prix de l\'Essence',
      FuelPriceReportPage: 'Prix de l\'Essence',
      EndDtMustbeEqualOrGreaterThanStartDt: 'La date de fin doit être le même jour ou ou postérieure à la date de début',
      Period: 'Période',
      Product: 'Produit',
      SSPrice: 'Prix LS',
      FSPrice: 'Prix AS',
      SSmargin: 'Marge LS',
      FSMargin: 'Marge AS',
      ExportToExcel: 'Exporter vers Excel',
      MysteryShopper: 'Boutiques tierces',
      ScoreOverTime: 'Score au fil du temps',
      ScoreComparison: 'Comparaison des notes',
      ScoreDetails: 'Détails du score',
      Dashboard: 'Tableau de bord',
      Section: 'Section',
      PreviousScore: 'Note précédente',
      CurrentScore: 'Score actuel',
      Difference: 'Différence',
      BU: 'BU',
      DocumentDate: 'Date du document',
      Province: 'Province',
      FuelBrand: 'Marque de carburant',
      ROM: 'ROM',
      TM: 'TM',
      OverallScore: 'Score global',
      InteriorScore: 'Score intérieur',
      ExteriorScore: 'Score extérieur',
      WashroomScore: 'Score des toilettes',
      CustomerService: 'Service Clients',
      LoyaltyAsk: 'Demande de fidélité',
      LoyaltyOffer: 'Offre de fidélité',
      UpsellProduct: 'Produit de vente incitative',
      Overall: 'Globale',
      InteriorCleanliness: 'Propreté Intérieure',
      ExteriorCleanliness: 'Propreté Extérieure',
      WashroomCleanliness: 'Propreté des Toilettes',
      RecordsReceived: 'Enregistrements reçus',
      AverageScore: 'Score moyen',
      AverageScorePercentage: 'Score moyen',
      NumberOfMysteryShops: '# Boutiques mystères',
      SearchColumn: 'Colonne de recherche',
      NoDataForSelectedStations: 'Il n\'y a pas de données pour la ou les stations sélectionnées',
      MysteryShopperReportFileName: 'Rapport de client mystère.csv',
      OTRDealer: 'Agents OTR',
      Station: 'Gare',
      WeatherNotAvailable: 'Désolé, la météo n\'est pas disponible en ce moment',
      ViewReport: 'Voir le rapport',
      StartDate: 'Date de début',
      EndDate: 'Date de fin',
      RiskSharing: 'Partage des risques',
      Loading: 'Chargement ...',
      SessionHasExpired: 'Votre session a expiré. Tenez bon pendant que nous actualisons vos informations.',
      MysteryShopAdminNoStationSelected: 'Vous êtes actuellement connecté en tant qu\'administrateur et vous n\'avez sélectionné aucun site. Veuillez sélectionner un site via le sélecteur de station dans la barre de navigation supérieure pour voir les données des acheteurs potentiels pour ce site.',
      LoadingRiskSharing: 'partage des risques au poste de chargement ...',
      Updates7Days: '7 jours',
      Updates15Days: '15 jours',
      Updates30Days: '30 jours',
      DateRange: 'Plage de dates',
      SearchDateRange: 'Plage de dates de recherche',
      UserActivityReport: 'Rapport d\'activité de l\'utilisateur',
      ActivityType: 'Type d\'activité',
      UserActivitysReportSummary: 'Afficher les différentes activités que les utilisateurs ont effectuées dans le système par date',
      UserActivityReportRoute: 'useractivityreport',
      CouldNotFindArticleWithTag: 'Désolé, nous n\'avons pas trouvé d\'articles avec le tag ',
      ArticlesWithTag: 'Articles avec étiquette',
      NoDealerCommissionResultsFound: 'Nous n\'avons trouvé aucun enregistrement pour la station sélectionnée dans la plage de dates sélectionnée.',
      BulkUpdateContent: 'Contenu de mise à jour en masse',
      BulkUpdate: 'Mise à jour en masse',
      PageName: 'Nom de la page',
      Metadata: 'Métadonnées',
      CarwashDashboard: 'Tableau de bord de lavage de voiture',
      PromotionalPeriods: 'Périodes promotionnelles',
      CarWash: 'Lave-Auto',
      ShareArticle: 'Partager l\'article',
      ViewUserActivityMetadata: 'Afficher les métadonnées',
      NoUserActivitiesFound: 'Nous n\'avons trouvé aucune activité d\'utilisateur dans la période sélectionnée',
      ViewingNumberOfStations: 'Affichage actuel du nombre de stations',
      MayNotBeDataForAllStations: 'Il se peut qu\'il n\'y ait pas de données pour toutes les stations',
      CouldNotSendEmail: 'Nous n\'avons pas pu envoyer l\'e-mail',
      ArticleSharedSuccessfully: 'L\'article a été partagé avec succès',
      SharedArticleWithYou: 'a partagé avec vous un article sur The Gateway',
      HelpEmailSubject: 'Nouvelle demande d\'aide de Parkland Gateway',
      ToName: 'Nom',
      ToEmail: 'Envoyer un email',
      EmailMessage: 'Un message',
      Subject: 'Sujet',
      NoStationFound: 'aucune station valide trouvée',
      PageTitle401: 'Autorisation requise',
      ErrorSummary401: '401 : Autorisation requise',
      ErrorDescription401: 'Vous n\'êtes pas autorisé à afficher la page demandée. Veuillez choisir une autre page dans les menus de navigation.',
      SVGAltDescription401: 'Autorisation requise',
      PageTitle404: 'Page non trouvée',
      ErrorSummary404: '404 : La page que vous recherchez n\'est pas ici',
      ErrorDescription404: 'Soit vous avez essayé une route louche, soit vous êtes venu ici par erreur. Quoi qu\'il en soit, essayez d\'utiliser la navigation.',
      SVGAltDescription404: 'Page non trouvée',
      PageTitle500: 'erreur du serveur',
      SVGAltDescription500: 'erreur du serveur',
      ArticleUnauthorizedPageTitle: 'Article non autorisé',
      ArticleUnauthorizedErrorSummary: 'Vous n\'êtes pas autorisé à voir cet article',
      ArticleUnauthorizedErrorDescription: 'L\'article demandé est pour une marque, une région ou un modèle d\'exploitation différent de la station que vous avez actuellement sélectionnée.',
      ArticleUnauthorizedSVGAltDescription: 'Article non autorisé',
      BackToHome: 'De retour à la maison',
      ThisPageIsUnderConstruction: 'Cette page est en cours de construction',
      FuelRunoutReportTitle: 'Rapport de manque de carburant',
      FuelRunoutReportSummary: 'Résumé du rapport de manque de carburant à venir',
      FuelRunoutReportRoute: 'fuelrunoutreport',
      DowngradeRunoutReportTitle: 'Downgrade and Runout Report (French)',
      DowngradeRunoutReportSummary: 'Downgrade Runout Report Summary to come (French)',
      DowngradeRunoutReportRoute: 'downgraderunoutreport',
      ManageTeam: 'Gérer l\'équipe',
      DownloadPDFReport: 'Télécharger le rapport PDF',
      PDFReportLoading: 'Chargement du rapport PDF',
      SelectValidDateIn2020OrAfter: 'Veuillez sélectionner une date de début et de fin valide en 2020 ou plus tard',
      SubmitServiceTicket: 'Soumettre un ticket de service',
      CouldNotSendServiceTicket: 'Nous n\'avons pas pu envoyer le ticket de service',
      ServiceTicketSentSuccessfully: 'Le ticket de service a été créé avec succès',
      DropOrSelectFiles: 'Faites glisser et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers',
      SelectedFiles: 'Fichiers sélectionnés',
      OpenFileDialog: 'Boîte de dialogue Ouvrir un fichier',
      FileName: 'Nom de fichier',
      PlanogramName: 'Nom du planogramme',
      RecordCount: 'Nombre d\'enregistrements',
      SearchTerm: 'Terme de recherche',
      HelpTopic: 'Rubrique d\'aide',
      ArticleName: 'Nom d\'article',
      ArticleID: 'ID de l\'article',
      ReportName: 'Nom du rapport',
      Error: 'Erreur',
      Phone: 'Téléphoner',
      AddNewUser: 'Ajouter un nouvel utilisateur',
      AddUser: 'Ajouter un utilisateur',
      UpdateUser: 'Mettre à jour l\'utilisateur',
      DeleteUser: 'Supprimer l\'utilisateur',
      Delete: 'Supprimer',
      PleaseSelectAStation: 'Veuillez sélectionner une station afin de gérer l\'équipe de cette station',
      TheUserWasSuccessfullyAdded: 'L\'utilisateur a été ajouté avec succès',
      SorryWeCouldNotAddTheNewUser: 'Désolé, nous n\'avons pas pu ajouter le nouvel utilisateur',
      TheUserWasSuccessfullyUpdated: 'L\'utilisateur a été mis à jour avec succès',
      SorryWeCouldNotUpdateTheUser: 'Désolé, nous n\'avons pas pu mettre à jour l\'utilisateur',
      AreYouSureYouWantToDeleteThisUserFromTheTeam: 'Voulez-vous vraiment supprimer cet utilisateur de l\'équipe ?',
      AreYouSureYouWantToDisableThisUserInTheTeam: 'Voulez-vous vraiment désactiver cet utilisateur ?',
      AreYouSureYouWantToEnableThisUserInTheTeam: 'Voulez-vous vraiment activer cet utilisateur ?',
      TheUserWasSuccessfullyDeleted: 'L\'utilisateur a été supprimé avec succès',
      SorryWeCouldNotDeleteTheUser: 'Désolé, nous n\'avons pas pu supprimer l\'utilisateur',
      TheUserWasSuccessfullyDisabled: 'L\'utilisateur a été désactivé avec succès',
      SorryWeCouldNotDisableTheUser: 'Désolé, nous n\'avons pas pu désactiver l\'utilisateur',
      TheUserWasSuccessfullyEnabled: 'L\'utilisateur a été activé avec succès',
      SorryWeCouldNotEnableTheUser: 'Désolé, nous n\'avons pas pu activer l\'utilisateur',
      FromEmail: 'De l\'email',
      FilesSelected: 'fichiers sélectionnés',
      SelectFiles: 'Sélectionnez les fichiers',
      FileBytes: 'octets',
      NoFilesSelected: 'aucun fichier sélectionné',
      EventsCalendar: 'Calendrier des événements',
      SubmitAServiceTicket: 'Soumettre un ticket de service maintenant',
      DoYouNeedToUpdateTeamUser: 'Avez-vous besoin de mettre à jour un ou plusieurs utilisateurs de votre équipe ? Veuillez soumettre un ticket de service spécifiant tous les utilisateurs et les champs qui doivent être mis à jour.',
      NoHSEMoments: 'Il n\'y a pas de moments Santé, Sécurité et Environnement',
      NoOTRArticles: 'Il n\'y a pas d\'articles OTR',
      HSEMoment: 'Instant sante et securite',
      Only5AttachmentsAllowed: 'Seulement 5 pièces jointes sont autorisées à la fois.',
      SorryWeCouldNotDownloadThePDFReport: 'Désolé, nous n\'avons pas pu télécharger le rapport PDF',
      CouldNotGetStationConfiguration: 'Nous n\'avons pas pu obtenir la configuration de la station',
      CouldNotGetPDFReport: 'impossible de charger le rapport pdf',
      OTRHub: 'Marché Express',
      OTRPromoOptions: 'Options promotionnelles',
      OTRPromoOptionA: 'A',
      OTRPromoOptionB: 'B',
      OTRPromoOptionC: 'C',
      OTRPromoOptionD: 'D',
      UpdatePromoCode: 'Mettre à jour le code promo',
      SiteHasNotMadeOTRSelection: 'Il n\'y a pas de code de promotion OTR sélectionné pour cette station pour la promotion en cours',
      CurrentOTRSelectionOn: 'Sélection actuelle du code de promotion effectuée le',
      CurrentlySelectedStationIsNotOTR: 'Vous n\'êtes pas autorisé à voir cette page car la station actuellement sélectionnée n\'est pas un revendeur OTR',
      CurrentlySelectedStationDoesNotHaveCarWash: 'Vous n\'êtes pas autorisé à voir cette page car la station actuellement sélectionnée n\'a pas de lave-auto',
      PromoCodeUpdatedSuccessfully: 'Votre code promotionnel était pour cette période a été mis à jour avec succès',
      DownloadMysteryShopPDF: 'Télécharger la boutique mystère PDF',
      AgeRestrictedShopsCount: 'Les magasins soumis à des restrictions d\'âge comptent',
      MysteryShopPassRate: 'le taux de réussite %',
      ShopType: 'Type de magasin',
      ThereAreCurrentlyNoCarwashArticles: 'Il n\'y a actuellement aucun article de lavage de voiture',
      CarWashArticles: 'Articles de lavage de voiture',
      MysteryShop: 'Boutique Mystère',
      AgeRestricted: 'Âge limité',
      TMAudit: 'TM Audit',
      OnlyImageFileTypesCurrentlySupported: 'Actuellement, seuls les types d\'images PNG, JPG et JPEG sont pris en charge pour les pièces jointes',
      ViewFuelPrice: 'Voir le prix du carburant',
      OnTheRun: 'Hub OTR',
      AverageForMysteryShops: 'Moyenne des boutiques mystères',
      AverageForAgeRestrictedShops: 'Moyenne des boutiques soumises à des restrictions d\'âge',
      PriceChangeType: 'Type de changement de prix',
      EffectiveDate: 'Date effective',
      FullPrice: 'Prix ​​du carburant',
      FuelType: 'Type de carburant',
      CouldNotLoadSearchResults: 'Désolé, nous n\'avons pu charger aucun résultat de recherche pour le moment. Veuillez contacter votre administrateur si cela persiste.',
      TermsAndConditionsPage: 'Termes et conditions',
      FuelRunoutReportPage: 'Rapport de manque de carburant',
      ReportAFuelRunOut: 'Signaler une panne de carburant',
      DowngradeRunoutReportPage: 'Downgrade & Runout Report (French)',
      EmailReportSentSuccessful: 'Email Report sent Successfully',
      UnableToEmailReport: 'Unable to Send Report!',
      BuySellModels: 'ACHETER/VENDRE DES MODÈLES',
      TheReportWillGoToYourTM: 'This report will go to you TM. If more information is required, your TM will reach out to you. (French)',
      TheSurveyWillTakeApproximately: 'The survey will take approximately 4 minutes to complete. (French)',
      PleaseCompleteAllAsApplicable: 'PLEASE COMPLETE ALL AS APPLICABLE REVIEW DATA VALIDATION ON THE RIGHT. ANY FORMS WITH RED WILL BE RETURNED. (French)',
      DigitJDEStation: 'Digit JDE Station Number (BU Number)/No SAP [4XXXX] or [5XXXX]',
      EmailAddress: 'Adresse E-mail',
      RunoutStartDate: 'Runout Start Date',
      RunoutStartTime: 'Runout Start Time',
      RunoutEndDate: 'Runout End Date',
      RunoutEndTime: 'Runout End Time',
      ProductOut: 'Product Out',
      DidDowngradeOccur: 'Did downgrade occur?',
      NumberOfLitresDowngraded: 'Number of Litres Downgraded',
      CommentsIfRunoutDowngrade: 'Comments (note here if runout/downgrade is not delivery related. Example mechanical or systems issues).',
      InternalDocumentationPage: 'Documentation interne',
      NoArticlesFound: 'Désolé, il n\'y a actuellement aucun article à afficher ici',
      NoCategoriesFound: 'Aucune catégorie n\'a été trouvée',
      DisableUser: 'Disable User',
      EnableUser: 'Enable User',
      Notifications: 'Avis',
      Refresh: 'rafraîchir',
      Status: 'Statut',
      OTROptions: 'Options OTR',
      ThereAreNoNotifications: 'Vous n\'avez actuellement aucune notification',
      ThereAreNewArticlesForYourStation: 'Il y a de nouveaux articles à lire pour votre station',
      OTRArticle: 'Article OTR',
      LatestOTRContent: 'Dernier contenu OTR',
      RefreshNotifications: 'Actualiser les notifications'
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
