// React
import { useRef, useState, useCallback, useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Material UI
import {
  Box,
  ButtonBase,
  Card,
  CircularProgress,
  IconButton,
  Grid,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import RefreshIcon from '@material-ui/icons/Refresh';

// Services
import { gatewayService } from 'src/services/gatewayService';
import { contentfulService } from 'src/services/contentfulService';

// Store
import type { RootState } from 'src/store';
import { useSelector } from 'src/store';

// Custom compontents and types
import ArticleSummary from 'src/types/articleSummary';
import GatewayStation from 'src/types/gateway/gatewayStation';

// Hools and Utils
import useMounted from 'src/hooks/useMounted';
import { appConfig } from 'src/config/config';
import { useTranslation } from 'react-i18next';
import logger from 'src/logging/logger';
import { getLogMsg } from 'src/utils/loggingUtils';
import { getArticleRoute } from 'src/utils/routeUtils';
import { getSessionEmail } from 'src/utils/authUtils';

interface SiteSpecificNotificationProps {
  article: ArticleSummary;
  onArticleLinkClicked(event: any, articleID: string);
}
const SiteSpecificNotification: FC<SiteSpecificNotificationProps> = ({ article, onArticleLinkClicked }: SiteSpecificNotificationProps) => (
  <ListItem disableGutters>
    <Card>
      <Box
        sx={{
          justifyContent: 'space-between',
          p: 1
        }}
      >
        <RouterLink
          to={getArticleRoute(article.id)}
          style={{ textDecoration: 'none', cursor: 'pointer' }}
          color="primary"
          onClick={(e) => onArticleLinkClicked(e, article.id)}
        >
          <Typography
            color="primary"
            variant="caption"
          >
            {`${article.title} - (${article.category}) `}
          </Typography>
        </RouterLink>
      </Box>
      <Box
        sx={{
          justifyContent: 'space-between',
          px: 1,
          pb: 1
        }}
      >
        <Typography
          color="GrayText"
          variant="caption"
        >
          {article.summary}
        </Typography>
      </Box>
    </Card>
  </ListItem>
);

const NotificationsPopover: FC = () => {
  // State variables
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userHasNewNotifications, setUserHasNewNotifications] = useState<boolean>(false);
  const [siteSpecificArticles, setSiteSpecificArticles] = useState<ArticleSummary[]>([]);

  // Constants
  const {
    notifications
  } = appConfig.i18n.pageTitles;
  const {
    refreshNotifications
  } = appConfig.i18n.actions;
  const {
    thereAreNoNotifications
  } = appConfig.i18n.messages;

  // Hooks and Utils
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t: translate, i18n } = useTranslation();
  const mounted = useMounted();
  const componentName = 'NotificationsPopover';

  // Selectors
  const selectedStation = useSelector((state: RootState): GatewayStation => state.userDetails.selectedStation);

  // Event handlers
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  // Functions
  const getNotifications = async () => {
    try {
      setIsLoading(true);
      if (selectedStation && selectedStation.site_id) {
        const siteArticles = await contentfulService.getSiteSpecficArticles(selectedStation.site_id, i18n.language);
        const userEmail = await getSessionEmail();
        const readArticles = await gatewayService.getArticlesReadByUserInStation(userEmail, selectedStation.site_id);
        const unreadArticles: ArticleSummary[] = [];
        siteArticles.forEach((article) => {
          if (!readArticles.includes(article.id)) unreadArticles.push(article);
        });
        setSiteSpecificArticles(unreadArticles);
        setUserHasNewNotifications(true);
      }
    } catch (err) {
      gatewayService.logErrorActivity(err);
      logger.error(getLogMsg(`${componentName} - getNotifications`, err));
    }
    setIsLoading(false);
  };

  const init = useCallback(async () => {
    try {
      await getNotifications();
    } catch (err) {
      gatewayService.logErrorActivity(err);
      logger.error(getLogMsg(`${componentName} - init`, err));
    }
  }, [mounted]);

  useEffect(() => {
    init();
  }, [init, selectedStation.site_id, i18n.language]);

  // Event Handlers
  const handleSiteSpecificArticleLinkClicked = (event: any, articleID: string) => {
    const newUnreadNotifications = [...siteSpecificArticles].filter((a) => a.id !== articleID);
    setSiteSpecificArticles(newUnreadNotifications);
  };

  const handleRefreshNotifications = async () => {
    await getNotifications();
  };

  return (
    <>
      <Tooltip title={translate(appConfig.i18n.actions.notifications)}>
        <Box
          component={ButtonBase}
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          {userHasNewNotifications
          && (
            <NotificationsActiveIcon color="action" />
          )}
          {!userHasNewNotifications
          && (
            <NotificationsNoneIcon color="disabled" />
          )}
        </Box>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            p: 1,
            backgroundColor: 'background.paper',
            width: 300,
            height: 400
          }
        }}
      >
        <Box sx={{ p: 1 }}>
          <Grid container>
            <Grid
              item
              xs={12}
            >
              <Typography
                color="GrayText"
                variant="subtitle1"
                fontWeight="700"
              >
                {translate(notifications)}
              </Typography>
            </Grid>
            {isLoading
            && (
              <Grid
                item
                xs={12}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1
                  }}
                >
                  <CircularProgress color="primary" />
                </Box>
              </Grid>
            )}
            {!isLoading
            && (
              <>
                <Grid
                  item
                  xs={12}
                  alignItems="baseline"
                  justifyContent="flex-end"
                >
                  <Tooltip title={translate(refreshNotifications)}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={handleRefreshNotifications}
                    >
                      <RefreshIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  {!userHasNewNotifications
                  && (
                    <Typography
                      color="GrayText"
                      variant="caption"
                    >
                      {translate(thereAreNoNotifications)}
                    </Typography>
                  )}
                  {userHasNewNotifications
                  && (
                    <List
                      style={{ backgroundColor: 'background.paper', height: '100%', width: '100%' }}
                      disablePadding
                    >
                      <ListItem disableGutters>
                        <Typography
                          color="GrayText"
                          variant="caption"
                        >
                          {translate(appConfig.i18n.messages.thereAreNewArticlesForYourStation)}
                        </Typography>
                      </ListItem>
                      {siteSpecificArticles
                        && siteSpecificArticles.length > 0
                        && siteSpecificArticles.map((article: ArticleSummary) => (
                          <SiteSpecificNotification
                            key={article.id}
                            article={article}
                            onArticleLinkClicked={handleSiteSpecificArticleLinkClicked}
                          />
                        ))}
                    </List>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
