// Contentful
import { Entry } from 'contentful';

// Custom types
import { GeneralLink } from 'src/types/contentful/generalLinkListCollection';
import { ContentfulPlanogramWithFilters } from 'src/types/contentful/stationPortalPlanogramCollection';
import ContentfulCriticalUpdateWithFilters from 'src/types/contentful/contentfulCriticalUpdateWithFilters';
import ContentfulLatestUpdateWithFilters from 'src/types/contentful/contentfulLatestUpdateWithFilters';
import ContentfulAnnouncementWithFilters from 'src/types/contentful/contentfulAnnouncementWithFilters';
import ContentfulArticleSummaryWithFilters from 'src/types/contentful/contentfulArticleSummaryWithFilters';
import ContentfulPlanogramSummaryWithFilters from 'src/types/contentful/contentfulPlanogramSummaryWithFilters';
import { GeneralCalendarEntryArticle } from 'src/types/contentful';
import { ArticleInTagWithFilters } from 'src/types/contentful/articlesInGeneralTagsCollection';

// Config
import { appConfig, contentfulConfig } from 'src/config/config';

// Constants
const {
  allBrandsCode,
  allRegionsCode,
} = contentfulConfig.query.values;
const {
  otrDealer: otrDealerFlag
} = appConfig.contentful.classOfTrades;

/**
 * Checks to see whether a station's brand matches the Contentful entry's brand array
 * @author Kevin Parkinson
 * @param {string} filter
 * @param {any[]} brands - the brands to search for a match
 * @return {boolean}
 * @comments checks to see whether specific brand is found or all brands
*/
export const brandMatch = (filter: string, brands: string[]): boolean => {
  if (!filter || !brands || brands.length === 0) return false;
  return brands.some((b) => b && ([filter, allBrandsCode].includes(b)));
};

/**
 * Checks to see whether a station's class of trade is found in the Contentful entry's class of trade array
 * @author Kevin Parkinson
 * @param {string} filter
 * @param {string[]} classOfTrades - the class of trades to search for a match
 * @return {boolean}
 * @comments checks to see whether specific class of trade is found
*/
export const classOfTradeMatch = (filter: string, classOfTrades: string[]): boolean => {
  if (!filter || !classOfTrades || classOfTrades.length === 0) return false;
  return classOfTrades.some((c) => c && filter === c);
};

/**
 * Checks to see whether a station's region matches the Contentful entry's region array
 * @author Kevin Parkinson
 * @param {string} filter
 * @param {string[]} regions - the regions to search for a match
 * @return {boolean}
 * @comments checks to see whether specific region is found or all regions
*/
export const regionMatch = (filter: string, regions: string[]): boolean => {
  if (!filter || !regions || regions.length === 0) return false;
  return regions.some((r) => r && ([filter, allRegionsCode].includes(r)));
};

/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param {boolean} isOTRDealer
 * @param {ClassOfTradeCollection} classOfTrades - the class of trades to search for a match
 * @return {boolean}
 * @comments checks to see whether the item should be returned based on whether t he station is an otr dealer
*/
export const otrDealerMatch = (classOfTrades: string[]): boolean => {
  if (!classOfTrades || classOfTrades.length === 0) return false;
  return classOfTrades.some((c) => c === otrDealerFlag);
};

/**
 * Checks to see whether a station's brand, region and class of trade matches the Contentful critical update brand,  region andclass  of trade
 * @author Kevin Parkinson
 * @param {string} siteID - the currently selected site id
 * @param {string} bFltr - the brand filter
 * @param {string} cotFltr- the class of trade filter
 * @param {string} rFltr- the region filter
 * @param {boolean} isOTRDealer- is the station an OTR dealer?
 * @param {Entry<ContentfulCriticalUpdateWithFilters>} item - the item to search for matches
 * @return {boolean}
 * @comments checks to see whether specific brand is found or all brands, the specific class of trade is found and the specific region is found or all regions
*/
export const filterCriticalUpdate = (siteID: string,
  bFltr: string,
  cotFltr: string,
  rFltr: string,
  isOTRDealer: boolean,
  item: Entry<ContentfulCriticalUpdateWithFilters>): boolean => {
  const { brands, regions, classOfTrade } = item.fields;
  if (item.fields.specificBu) {
    if (item.fields.specificBu.toString() !== siteID) {
      return false;
    }
  }
  if (!brands || !regions || !classOfTrade || brands.length === 0 || regions.length === 0 || classOfTrade.length === 0) return false;
  const classOfTradeStrings = classOfTrade.map((c) => (c && c.fields && c.fields.classOfTrade ? c.fields.classOfTrade : ''));
  if (isOTRDealer) {
    return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
      && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
      && otrDealerMatch(classOfTradeStrings);
  }
  return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
    && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
    && classOfTradeMatch(cotFltr, classOfTradeStrings);
};

/**
 * Checks to see whether a station's brand, region and class of trade matches the Contentful announcement brand, region and class of trade
 * @author Kevin Parkinson
 * @param {string} siteID - the currently selected site id
 * @param {string} bFltr - the brand filter
 * @param {string} cotFltr- the class of trade filter
 * @param {string} rFltr- the region filter
 * @param {boolean} isOTRDealer- is the station an OTR dealer?
 * @param {Entry<ContentfulAnnouncementWithFilters>} item - the item to search for matches
 * @return {boolean}
 * @comments checks to see whether specific brand is found or all brands, the specific class of trade is found and the specific region is found or all regions
*/
export const filterAnnouncement = (siteID: string, bFltr: string, cotFltr: string, rFltr: string, isOTRDealer: boolean, item: Entry<ContentfulAnnouncementWithFilters>): boolean => {
  const { brands, regions, classOfTrade } = item.fields;
  if (item.fields.specificBu) {
    if (item.fields.specificBu.toString() !== siteID) {
      return false;
    }
  }
  if (!brands || !regions || !classOfTrade || brands.length === 0 || regions.length === 0 || classOfTrade.length === 0) return false;
  const classOfTradeStrings = classOfTrade.map((c) => (c && c.fields && c.fields.classOfTrade ? c.fields.classOfTrade : ''));
  if (isOTRDealer) {
    return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
      && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
      && otrDealerMatch(classOfTradeStrings);
  }
  return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
    && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
    && classOfTradeMatch(cotFltr, classOfTradeStrings);
};

/**
 * Checks to see whether a station's brand, region and class of trade matches the Contentful update in last 7 days brand, region and class  of trade
 * @author Kevin Parkinson
 * @param {string} siteID - the currently selected site id
 * @param {string} bFltr - the brand filter
 * @param {string} cotFltr- the class of trade filter
 * @param {string} rFltr- the region filter
 * @param {boolean} isOTRDealer- is the station an OTR dealer?
 * @param {Entry<ContentfulLatestUpdateWithFilters>} item - the item to search for matches
 * @return {boolean}
 * @comments checks to see whether specific brand is found or all brands, the specific class of trade is found and the specific region is found or all regions
*/
export const filterLatestUpdate = (siteID: string, bFltr: string, cotFltr: string, rFltr: string, isOTRDealer: boolean, item: Entry<ContentfulLatestUpdateWithFilters>): boolean => {
  const { brands, regions, classOfTrade } = item.fields;
  if (item.fields.specificBu) {
    if (item.fields.specificBu.toString() !== siteID) {
      return false;
    }
  }
  if (!brands || !regions || !classOfTrade || brands.length === 0 || regions.length === 0 || classOfTrade.length === 0) return false;
  const classOfTradeStrings = classOfTrade.map((c) => (c && c.fields && c.fields.classOfTrade ? c.fields.classOfTrade : ''));
  if (isOTRDealer) {
    return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
      && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
      && otrDealerMatch(classOfTradeStrings);
  }
  return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
    && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
    && classOfTradeMatch(cotFltr, classOfTradeStrings);
};

/**
 * Checks to see whether a station's brand, region and class of trade matches the Contentful article summary brand, region and class of trade
 * @author Kevin Parkinson
 * @param {string} siteID - the currently selected site id
 * @param {string} bFltr - the brand filter
 * @param {string} cotFltr- the class of trade filter
 * @param {string} rFltr- the region filter
 * @param {boolean} isOTRDealer- is the station an OTR dealer?
 * @param {Entry<ContentfulArticleSummaryWithFilters>} item - the item to search for matches
 * @return {boolean}
 * @comments checks to see whether specific brand is found or all brands, the specific class of trade is found and the specific region is found or all regions
*/
export const filterArticleSummary = (siteID: string, bFltr: string, cotFltr: string, rFltr: string, isOTRDealer: boolean, item: Entry<ContentfulArticleSummaryWithFilters>): boolean => {
  const { brands, regions, classOfTrade } = item.fields;
  if (item.fields.specificBu) {
    if (item.fields.specificBu.toString() !== siteID) {
      return false;
    }
  }
  if (!brands || !regions || !classOfTrade || brands.length === 0 || regions.length === 0 || classOfTrade.length === 0) return false;
  const classOfTradeStrings = classOfTrade.map((c) => (c && c.fields && c.fields.classOfTrade ? c.fields.classOfTrade : ''));
  if (isOTRDealer) {
    return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
      && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
      && otrDealerMatch(classOfTradeStrings);
  }
  return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
    && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
    && classOfTradeMatch(cotFltr, classOfTradeStrings);
};

/**
 * Checks to see whether a station's brand, region and class of trade matches the Contentful article summary brand, region and class of trade
 * @author Kevin Parkinson
 * @param {string} siteID - the currently selected site id
 * @param {string} bFltr - the brand filter
 * @param {string} cotFltr- the class of trade filter
 * @param {string} rFltr- the region filter
 * @param {boolean} isOTRDealer- is the station an OTR dealer?
 * @param {Entry<ContentfulArticleSummaryWithFilters>} item - the item to search for matches
 * @return {boolean}
 * @comments checks to see whether specific brand is found or all brands, the specific class of trade is found and the specific region is found or all regions
*/
export const filterArticleSummaryInTag = (siteID: string, bFltr: string, cotFltr: string, rFltr: string, isOTRDealer: boolean, item: Entry<ArticleInTagWithFilters>): boolean => {
  const { brands, regions, classOfTrade } = item.fields;
  if (item.fields.specificBu) {
    if (item.fields.specificBu.toString() !== siteID) {
      return false;
    }
  }
  if (!brands || !regions || !classOfTrade || brands.length === 0 || regions.length === 0 || classOfTrade.length === 0) return false;
  const classOfTradeStrings = classOfTrade.map((c) => (c && c.fields && c.fields.classOfTrade ? c.fields.classOfTrade : ''));
  if (isOTRDealer) {
    return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
      && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
      && otrDealerMatch(classOfTradeStrings);
  }
  return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
    && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
    && classOfTradeMatch(cotFltr, classOfTradeStrings);
};

/**
 * Checks to see whether a station's brand, region and class of trade matches the Contentful Planogram brand, region and class of trade
 * @author Kevin Parkinson
 * @param {string} bFltr - the brand filter
 * @param {string} cotFltr- the class of trade filter
 * @param {string} rFltr- the region filter
 * @param {boolean} isOTRDealer- is the station an OTR dealer?
 * @param {Entry<ContentfulPlanogramWithFilters>} item - the item to search for matches
 * @return {boolean}
 * @comments checks to see whether specific brand is found or all brands, the specific class of trade is found and the specific region is found or all regions
*/
export const filterPlanogram = (bFltr: string, cotFltr: string, rFltr: string, isOTRDealer: boolean, item: ContentfulPlanogramWithFilters): boolean => {
  const { brandsCollection: brands, regionsCollection: regions, classOfTradeCollection: classOfTrade } = item;
  if (!brands || !brands.items || brands.items.length === 0
    || !regions || !regions.items || regions.items.length === 0
    || !classOfTrade || !classOfTrade.items || classOfTrade.items.length === 0) return false;
  const classOfTradeStrings = classOfTrade.items.map((c) => (c && c.classOfTrade ? c.classOfTrade : ''));
  if (isOTRDealer) {
    return brandMatch(bFltr, brands.items.map((b) => (b && b.mdmCode ? b.mdmCode : '')))
      && regionMatch(rFltr, regions.items.map((r) => (r && r.mdmCode ? r.mdmCode : '')))
      && otrDealerMatch(classOfTradeStrings);
  }
  return brandMatch(bFltr, brands.items.map((b) => (b && b.mdmCode ? b.mdmCode : '')))
    && regionMatch(rFltr, regions.items.map((r) => (r && r.mdmCode ? r.mdmCode : '')))
    && classOfTradeMatch(cotFltr, classOfTradeStrings);
};

/**
 * Checks to see whether a station's brand, region and class of trade matches the Contentful Planogram brand, region and class of trade
 * @author Kevin Parkinson
 * @param {string} siteID - the currently selected site id
 * @param {string} bFltr - the brand filter
 * @param {string} cotFltr- the class of trade filter
 * @param {string} rFltr- the region filter
 * @param {boolean} isOTRDealer- is the station an OTR dealer?
 * @param {Entry<ContentfulPlanogramWithFilters>} item - the item to search for matches
 * @return {boolean}
 * @comments checks to see whether specific brand is found or all brands, the specific class of trade is found and the specific region is found or all regions
*/
export const filterArticleSummaryTagged = (siteID: string, bFltr: string, cotFltr: string, rFltr: string, isOTRDealer: boolean, item: any): boolean => {
  const { brandsCollection: brands, regionsCollection: regions, classOfTradeCollection: classOfTrade } = item;
  if (item.specificBu) {
    if (item.specificBu.toString() !== siteID) {
      return false;
    }
  }
  if (!brands || !brands.items || brands.items.length === 0
    || !regions || !regions.items || regions.items.length === 0
    || !classOfTrade || !classOfTrade.items || classOfTrade.items.length === 0) return false;
  const classOfTradeStrings = classOfTrade.items.map((c) => (c && c.classOfTrade ? c.classOfTrade : ''));
  if (isOTRDealer) {
    return brandMatch(bFltr, brands.items.map((b) => (b && b.mdmCode ? b.mdmCode : '')))
      && regionMatch(rFltr, regions.items.map((r) => (r && r.mdmCode ? r.mdmCode : '')))
      && otrDealerMatch(classOfTradeStrings);
  }
  return brandMatch(bFltr, brands.items.map((b) => (b && b.mdmCode ? b.mdmCode : '')))
    && regionMatch(rFltr, regions.items.map((r) => (r && r.mdmCode ? r.mdmCode : '')))
    && classOfTradeMatch(cotFltr, classOfTradeStrings);
};

/**
 * Checks to see whether a station's brand, region and class of trade matches the Contentful Planogram brand, region and class of trade
 * @author Kevin Parkinson
 * @param {string} bFltr - the brand filter
 * @param {string} cotFltr- the class of trade filter
 * @param {string} rFltr- the region filter
 * @param {boolean} isOTRDealer- is the station an OTR dealer?
 * @param {Entry<ContentfulPlanogramWithFilters>} item - the item to search for matches
 * @return {boolean}
 * @comments checks to see whether specific brand is found or all brands, the specific class of trade is found and the specific region is found or all regions
*/
export const filterPlanogramSummary = (bFltr: string, cotFltr: string, rFltr: string, isOTRDealer: boolean, item: Entry<ContentfulPlanogramSummaryWithFilters>): boolean => {
  const { brands, regions, classOfTrade } = item.fields;
  if (!brands || !regions || !classOfTrade || brands.length === 0 || regions.length === 0 || classOfTrade.length === 0) return false;
  const classOfTradeStrings = classOfTrade.map((c) => (c && c.fields && c.fields.classOfTrade ? c.fields.classOfTrade : ''));
  if (isOTRDealer) {
    return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
      && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
      && otrDealerMatch(classOfTradeStrings);
  }
  return brandMatch(bFltr, brands.map((b) => (b && b.fields && b.fields.mdmCode ? b.fields.mdmCode : '')))
    && regionMatch(rFltr, regions.map((r) => (r && r.fields && r.fields.mdmCode ? r.fields.mdmCode : '')))
    && classOfTradeMatch(cotFltr, classOfTradeStrings);
};

/**
 * Checks to see whether a station's brand, region and class of trade matches the Contentful Link brand, region and class of trade
 * @author Kevin Parkinson
 * @param {string} bFltr - the brand filter
 * @param {string} cotFltr- the class of trade filter
 * @param {string} rFltr- the region filter
 * @param {boolean} isOTRDealer- is the station an OTR dealer?
 * @param {GeneralLink} item - the item to search for matches
 * @return {boolean}
 * @comments checks to see whether specific brand is found or all brands, the specific class of trade is found and the specific region is found or all regions
*/
export const filterLink = (bFltr: string, cotFltr: string, rFltr: string, isOTRDealer: boolean, item: GeneralLink): boolean => {
  const { brandsCollection: brands, regionsCollection: regions, classOfTradeCollection: classOfTrade } = item;
  if (!brands || !brands.items || brands.items.length === 0
    || !regions || !regions.items || regions.items.length === 0
    || !classOfTrade || !classOfTrade.items || classOfTrade.items.length === 0) return false;
  const classOfTradeStrings = classOfTrade.items.map((c) => (c && c.classOfTrade ? c.classOfTrade : ''));
  if (isOTRDealer) {
    return brandMatch(bFltr, brands.items.map((b) => (b && b.mdmCode ? b.mdmCode : '')))
      && regionMatch(rFltr, regions.items.map((r) => (r && r.mdmCode ? r.mdmCode : '')))
      && otrDealerMatch(classOfTradeStrings);
  }
  return brandMatch(bFltr, brands.items.map((b) => (b && b.mdmCode ? b.mdmCode : '')))
    && regionMatch(rFltr, regions.items.map((r) => (r && r.mdmCode ? r.mdmCode : '')))
    && classOfTradeMatch(cotFltr, classOfTradeStrings);
};

/**
 * Checks to see whether a station's brand, region and class of trade matches the Contentful Link brand, region and class of trade
 * @author Kevin Parkinson
 * @param {string} siteID - the currently selected site id
 * @param {string} bFltr - the brand filter
 * @param {string} cotFltr- the class of trade filter
 * @param {string} rFltr- the region filter
 * @param {boolean} isOTRDealer- is the station an OTR dealer?
 * @param {Entry<CGeneralCalendarEntryCollection>} item - the item to search for matches
 * @return {boolean}
 * @comments checks to see whether specific brand is found or all brands, the specific class of trade is found and the specific region is found or all regions
*/
export const filterCalendarEvent = (siteID: string, bFltr: string, cotFltr: string, rFltr: string, isOTRDealer: boolean, item: GeneralCalendarEntryArticle): boolean => {
  const { brandsCollection: brands, regionsCollection: regions, classOfTradeCollection: classOfTrade } = item;
  if (item.specificBu) {
    if (item.specificBu.toString() !== siteID) {
      return false;
    }
  }
  if (!brands || brands.items.length === 0
    || !regions || regions.items.length === 0
    || !classOfTrade || classOfTrade.items.length === 0) return false;
  const classOfTradeStrings = classOfTrade.items.map((c) => (c && c.classOfTrade ? c.classOfTrade : ''));
  if (isOTRDealer) {
    return brandMatch(bFltr, brands.items.map((b) => (b && b.mdmCode ? b.mdmCode : '')))
      && regionMatch(rFltr, regions.items.map((r) => (r && r.mdmCode ? r.mdmCode : '')))
      && otrDealerMatch(classOfTradeStrings);
  }
  return brandMatch(bFltr, brands.items.map((b) => (b && b.mdmCode ? b.mdmCode : '')))
    && regionMatch(rFltr, regions.items.map((r) => (r && r.mdmCode ? r.mdmCode : '')))
    && classOfTradeMatch(cotFltr, classOfTradeStrings);
};
