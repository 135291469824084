// React Stuff
import type { FC } from 'react';
import { useEffect, useState, useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material UI
import { AppBar, Box, IconButton, Toolbar, Tooltip } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import { Favorite } from '@material-ui/icons';

// Custom components and types
import MenuIcon from 'src/icons/Menu';
import LanguagePopover from './LanguagePopover';
import ContentSearch from './ContentSearch';
import NotificationsPopover from './NotificationsPopover';
import AccountPopover from './AccountPopover';
import StationPickerPopup from './StationPickerPopup';
// import ChecklistPopover from './ChecklistPopover';
import HelpPopover from './HelpPopover';
import DashboardNavbarProps from 'src/types/props/dashboardNavbarProps';
import GatewayUser, { userHasStationsAssigned } from 'src/types/gateway/gatewayUser';
import FuelPriceWidget from '../widgets/FuelPriceWidget';

// Services
import { gatewayService } from 'src/services/gatewayService';

// Store
import type { RootState } from 'src/store';
import { useSelector } from 'src/store';

// Hooks and utils
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import useMounted from 'src/hooks/useMounted';
import logger from 'src/logging/logger';
import { getLogMsg } from 'src/utils/loggingUtils';
import GatewayStation from 'src/types/gateway/gatewayStation';

// Configuration
import { appConfig } from 'src/config/config';
import {
  updateUserActivityDateTime
} from 'src/utils/cookieUtils';

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  // Hooks and utils
  const { t: translate, i18n } = useTranslation();
  const { user } = useAuth();
  const mounted = useMounted();
  const urlLocation = useLocation();

  // State variables
  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language);
  const selectedStation = useSelector((state: RootState): GatewayStation | null => state.userDetails.selectedStation);

  // Constants
  const { onSidebarOpen, ...other } = props;
  const {
    gatewayBannerEnglish,
    gatewayBannerFrench
  } = appConfig.images;
  const {
    i18nEnglish,
    i18nFrench
  } = appConfig.i18n.languages;

  // Store
  const gatewayUser = useSelector((state: RootState): GatewayUser | null => state.userDetails.gatewayUserDetails);

  const init = useCallback(async () => {
    if (mounted.current) {
      try {
        setSelectedLanguage(i18n.language);
      } catch (err) {
        gatewayService.logErrorActivity(err);
        logger.error(getLogMsg('init', err));
      }
    }
  }, [mounted]);

  useEffect(() => {
    init();
  }, [init, i18n.language]);

  const handleOnSidebarOpen = () => {
    // record that user had activity
    updateUserActivityDateTime();
    onSidebarOpen();
  };

  // Main UI
  if (!user) return null;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar>
        <IconButton
          color="primary"
          onClick={handleOnSidebarOpen}
          onMouseOver={handleOnSidebarOpen}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          {selectedLanguage === i18nEnglish
          && (
            <img
              src={`${process.env.PUBLIC_URL}/static/${gatewayBannerEnglish}`}
              alt={translate(appConfig.i18n.navbar.HomePage)}
            />
          )}
          {selectedLanguage === i18nFrench
          && (
            <img
              src={`${process.env.PUBLIC_URL}/static/${gatewayBannerFrench}`}
              alt={translate(appConfig.i18n.navbar.HomePage)}
            />
          )}
        </RouterLink>
        {urlLocation.pathname === '/'
          && (
            <Box
              sx={{
                flexGrow: 1,
                ml: 2
              }}
            >
              {selectedStation && selectedStation.site_id
                && (
                  <FuelPriceWidget />
                )}
            </Box>
          )}
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>
        <RouterLink to={appConfig.routes.favourites}>
          <Tooltip title={translate(appConfig.i18n.pageTitles.favourites)}>
            <IconButton color="primary">
              <Favorite
                color="primary"
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        </RouterLink>
        {/*
          Hide the checklist for now
        <Box sx={{ ml: 1 }}>
          <ChecklistPopover />
        </Box> */}
        {/* show the staion picker if the user is an admin, a tm or they are a multi-site operator or have multiple assigned stations */}
        {gatewayUser && (gatewayUser.is_admin || gatewayUser.is_super || gatewayUser.is_tm || userHasStationsAssigned(gatewayUser))
        && (
          <Box sx={{ ml: 1 }}>
            <StationPickerPopup />
          </Box>
        )}
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <HelpPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <AccountPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <LanguagePopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};

export default DashboardNavbar;
