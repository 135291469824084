// Contentful
import { createClient, EntryCollection, Entry } from 'contentful';
import ApolloClient from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

// Custom types
// Types coming from Contentful
import {
  CAnnouncementWithFilters,
  CArticle,
  CArticleSummary,
  CArticleSummaryWithFilters,
  CBrand,
  CCategory,
  CClassOfTrade,
  CCriticalUpdateWithFilters,
  CDivision,
  CFAQ,
  CPlanogramSummary,
  CRegion,
  CSubcategory,
  CTag,
  CLatestUpdateWithFilters,
  CPlanogramCollection,
  CGeneralListGroupsCollection,
  CGeneralLinkListCollection,
  CPlanogramCategory,
  CPlanogram,
  CPlanogramSummaryWithFilters,
  CPromoPeriodArticle,
  CGeneralCalendarEntryCollection,
  CHSEMoment
} from 'src/types/contentful';
// Types for passing to the UI
import Article from 'src/types/article';
import ArticleSummary from 'src/types/articleSummary';
import CriticalUpdate from 'src/types/criticalUpdate';
import Announcement from 'src/types/announcement';
import LatestUpdate from 'src/types/latestUpdate';
import Brand from 'src/types/brand';
import { Category } from 'src/types/enums/enums';
import Link from 'src/types/link';
import Planogram from 'src/types/planogram';
import Contact from 'src/types/contact';
import FAQ from 'src/types/faq';
import TermsAndConditions from 'src/types/termsAndConditions';
import LanguageOption from 'src/types/languageOption';
import { contentfulQueryConstants } from 'src/constants';
import LookupItem from 'src/types/lookupItem';
import PlanogramSummary from 'src/types/planogramSummary';
import FavouritedPlanogram from 'src/types/favouritedPlanogram';
import GatewayStation, { classOfTradeCode } from 'src/types/gateway/gatewayStation';
import HealthAndSafetyMoment from 'src/types/healthAndSafetyMoment';
import UserRole from 'src/types/userRole';
import {
  filterCriticalUpdate,
  filterAnnouncement,
  filterLatestUpdate,
  filterArticleSummary,
  filterPlanogram,
  filterPlanogramSummary,
  filterLink,
  filterCalendarEvent,
  filterArticleSummaryTagged
} from 'src/services/resources/contentfulFilters';
import BigCalendarEvent from 'src/types/bigCalendarEvent';

// Querying Contentful
import {
  q,
  queryArticlesByCategory,
  queryFavouritedArticles,
  queryByContentType,
  queryBrandByName,
  queryClassOfTrades,
  searchArticles,
  searchArticlesByTag,
  searchArticlesWithFilters,
  searchPlanograms,
  searchPlanogramsWithFilters,
  queryRegions,
  queryTags,
  queryBrands,
  queryDivisions,
  queryEntries,
  queryCriticalUpdates,
  queryAnnouncements,
  queryPlanogramsByCategory,
  queryAllArticlesAsSummaries,
  queryCategories,
  querySubCategories,
  queryFAQs,
  queryExternalLinks,
  queryFavouritedPlanograms,
  queryLinks,
  queryAllLinks,
  queryAcknowledgeableArticles,
  queryOtherAnnouncements,
  queryOtherCriticalUpdates,
  queryUpdatesInLastNDays,
  queryUpdatesInDateRange,
  queryPlanogramCategories,
  queryFavouritedPlanogramsFull,
  queryAcknowledgeableArticlesInCategory,
  queryOtherUpdatesInLastNDays,
  queryTag,
  queryArticlesWithTag,
  queryArticlesWithTagWithFilters,
  queryCalendarArticles,
  queryEventsWithFilters,
  queryLatestHSEMoment,
  queryCarWashArticles,
  queryAdminArticles,
  queryTMROMArticles,
  querySiteSpecificArticles,
  queryLatestOTRArticle,
  queryTermsAndConditionsArticle
} from 'src/services/resources/contentfulQueries';

// Data conversion
import {
  toArticle,
  toArticles,
  toArticleSummaries,
  toPlanogramSummaries,
  toPlanogramsWithFilters,
  toExternalLinks,
  toAnnouncements,
  toCriticalUpdates,
  toLatestUpdates,
  toLinks,
  toLinksWithFilters,
  simplifyContentfulBrand,
  simplifyContentfulCategories,
  simplifyContentfulSubcategories,
  toFavouritedPlanograms,
  toPlanogramSummariesFiltered,
  taggedArticleToArticleSummary,
  articlesToCalendarEvents,
  articlesToCalendarEventsWithFilters,
  toHSEMoment
} from 'src/converters/contentful';

// Data
import helpTopics from 'src/data/helpTopics';
import { introspectionQueryResultData } from './resources/contentfulFragmentTypes';

// Utils and config
import { standardRejectedPromise, contentfulError } from 'src/services/resources/requestResponse';
import { appConfig, contentfulConfig } from 'src/config/config';
import { toArticleSummariesFiltered } from 'src/converters/contentful/articleConverter';

const {
  space_id: spaceID,
  environment,
  accessToken
} = contentfulConfig;
const serviceName = '[Contentful Service]';

// contentful client init
const rest = createClient({
  space: spaceID,
  environment,
  accessToken
});

// Contentful GraphQL Api init
const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });
const graphQL = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => contentfulError(serviceName, `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
      }
      if (networkError) contentfulError(serviceName, '[Network error]', networkError);
    }),
    new HttpLink({
      uri: `https://graphql.contentful.com/content/v1/spaces/${spaceID}/environments/${environment}`,
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
  ]),
  cache: new InMemoryCache({ fragmentMatcher })
});

// Constants
const {
  stationPortalArticle,
  contact
} = contentfulQueryConstants;

class ContentfulService {
  /**
   * Gets a list of all Entries in Contentful
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {EntryCollection<unknown>}
   * @comments mainly for testing
  */
  async getAllEntries(locale: string): Promise<EntryCollection<any>> {
    return new Promise((resolve, reject) => {
      rest.getEntries(queryEntries(locale))
        .then((data) => { resolve(data); })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets one entry
   * @author Kevin Parkinson
   * @param {string} id - the Contentful id of the entry
   * @param {string} locale - the Contentful locale to get content in
   * @return {EntryCollection<unknown>}
   * @comments mainly for testing
  */
  async getEntry(id: string, locale: string): Promise<Entry<any>> {
    return new Promise((resolve, reject) => {
      rest.getEntry(queryEntries(locale))
        .then((data) => { resolve(data); })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  // Content Types
  /**
   * Gets a list of Brands in Contentful format to a much more lean and flat structure
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<string[]>}
  */
  async getBrands(locale: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CBrand>(queryBrands(locale))
        .then((data) => { resolve(data.items.map((b) => (b.fields.mdmCode))); })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of class of trade in Contentful format to a much more lean and flat structure
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<string[]>}
  */
  async getClassOfTrades(locale: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CClassOfTrade>(queryClassOfTrades(locale))
        .then((data) => resolve(data.items.map((cot) => (cot.fields.classOfTrade))))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of divisions in Contentful format to a much more lean and flat structure
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<string[]>}
  */
  async getDivisions(locale: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CDivision>(queryDivisions(locale))
        .then((data) => { resolve(data.items.map((d) => (d.fields.division))); })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of regions in Contentful format to a much more lean and flat structure
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<string[]>}
  */
  async getRegions(locale: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CRegion>(queryRegions(locale))
        .then((data) => { resolve(data.items.map((r) => (r.fields.mdmCode))); })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of tags in Contentful format to a much more lean and flat structure
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<LookupItem[]>}
  */
  async getTags(locale: string): Promise<LookupItem[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CTag>(queryTags(locale))
        .then((data) => { resolve(data.items.map((t) => ({ id: t.sys.id, name: t.fields.tag }))); })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  // ARTICLES
  /**
   * Gets a list of all articles in Contentful format to a much more lean and flat structure
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Article[]>}
  */
  async getAllArticles(locale: string): Promise<Article[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticle>(queryByContentType(stationPortalArticle, locale))
        .then((data) => resolve(toArticles(data)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all articles in Contentful format to a much more lean, flat and summarised structure
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]>}
  */
  async getAllArticleSummaries(locale: string): Promise<ArticleSummary[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticleSummary>(queryAllArticlesAsSummaries(locale))
        .then((data) => resolve(toArticleSummaries(data.items)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all articles needing acknowledgement in Contentful format to a much more lean, flat and summarised structure
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]>}
  */
  async getAcknowledgeableArticles(locale: string): Promise<ArticleSummary[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticleSummary>(queryAcknowledgeableArticles(locale))
        .then((data) => resolve(toArticleSummaries(data.items)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all articles needing acknowledgement in a specific category from Contentful format to a much more lean, flat and summarised structure
   * @author Kevin Parkinson
   * @param {string} category - the article category to get articles for
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]>}
  */
  async getAcknowledgeableArticlesInCategory(category: string, locale: string): Promise<ArticleSummary[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticleSummary>(queryAcknowledgeableArticlesInCategory(category, locale))
        .then((data) => resolve(toArticleSummaries(data.items)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all critical updates in Contentful format to a much more lean, flat and summarised structure and filtered by brand, region and class of trade
   * @author Kevin Parkinson
   * @param {GatewayStation} station
   * @param {string[]} userAcknowledgedArticles
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<CriticalUpdate[]>}

  */
  async getCriticalUpdates(station: GatewayStation, userAcknowledgedArticles: string[], locale: string): Promise<CriticalUpdate[]> {
    return new Promise((resolve, reject) => {
      const { site_id: siteID, brand, province, is_otr_dealer: isOTRDealer } = station;
      rest.getEntries<CCriticalUpdateWithFilters>(queryCriticalUpdates(locale))
        .then((data) => {
          let filteredData = data.items.filter((d) => filterCriticalUpdate(siteID, brand, classOfTradeCode(station), province, isOTRDealer, d));
          filteredData = filteredData.filter((d) => !userAcknowledgedArticles.includes(d.sys.id));
          const criticalUpdates = toCriticalUpdates(filteredData);
          resolve(criticalUpdates);
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all critical updates in Contentful format to a much more lean, flat and summarised structure
   * @author Kevin Parkinson
   * @param {string[]} userAcknowledgedArticles
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<CriticalUpdate[]>}
  */
  async getAllCriticalUpdates(userAcknowledgedArticles: string[], locale: string): Promise<CriticalUpdate[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CCriticalUpdateWithFilters>(queryCriticalUpdates(locale))
        .then((data) => {
          const filteredData = data.items.filter((d) => !userAcknowledgedArticles.includes(d.sys.id) && (!d.fields.specificBu || d.fields.specificBu.length === 0)); // don't include articles with specific a BU
          resolve(toCriticalUpdates(filteredData));
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all announcements in Contentful format to a much more lean, flat and summarised structure and filtered by brand, region and class of trade
   * @author Kevin Parkinson
   * @param {GatewayStation} station
   * @param {string[]} userAcknowledgedArticles
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Announcement[]>}
  */
  async getAnnouncements(station: GatewayStation, userAcknowledgedArticles: string[], locale: string): Promise<Announcement[]> {
    return new Promise((resolve, reject) => {
      const { brand, province, is_otr_dealer: isOTRDealer } = station;
      rest.getEntries<CAnnouncementWithFilters>(queryAnnouncements(locale))
        .then((data) => {
          const filteredData = data.items.filter((d) => filterAnnouncement(station.site_id, brand, classOfTradeCode(station), province, isOTRDealer, d) && !userAcknowledgedArticles.includes(d.sys.id));
          resolve(toAnnouncements(filteredData));
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all announcements in Contentful format to a much more lean, flat and summarised structure
   * @author Kevin Parkinson
   * @param {string[]} userAcknowledgedArticles
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Announcement[]>}
  */
  async getAllAnnouncements(userAcknowledgedArticles: string[], locale: string): Promise<Announcement[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CAnnouncementWithFilters>(queryAnnouncements(locale))
        .then((data) => {
          const filteredData = data.items.filter((d) => !userAcknowledgedArticles.includes(d.sys.id) && (!d.fields.specificBu || d.fields.specificBu.length === 0));
          resolve(toAnnouncements(filteredData));
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all all updates in last 7 days in Contentful format to a much more lean, flat and summarised structure and filtered by brand, region and class of trade
   * @author Kevin Parkinson
   * @param {number} daysAgo
   * @param {GatewayStation} station
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<LatestUpdate[]>}
  */
  async getUpdatesInLastNDays(daysAgo: number, station: GatewayStation, locale: string): Promise<LatestUpdate[]> {
    const { brand, province, is_otr_dealer: isOTRDealer } = station;
    return new Promise((resolve, reject) => {
      rest.getEntries<CLatestUpdateWithFilters>(queryUpdatesInLastNDays(daysAgo, locale))
        .then((data) => {
          const filteredData = data.items.filter((d) => filterLatestUpdate(station.site_id, brand, classOfTradeCode(station), province, isOTRDealer, d));
          const updates = toLatestUpdates(filteredData);
          resolve(updates);
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all all updates in last 7 days in Contentful format to a much more lean, flat and summarised structure and filtered by brand, region and class of trade
   * @author Kevin Parkinson
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {GatewayStation} station
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<LatestUpdate[]>}
  */
  async getUpdatesInDateRange(startDate: Date, endDate: Date, station: GatewayStation, locale: string): Promise<LatestUpdate[]> {
    const { brand, province, is_otr_dealer: isOTRDealer } = station;
    return new Promise((resolve, reject) => {
      rest.getEntries<CLatestUpdateWithFilters>(queryUpdatesInDateRange(startDate, endDate, locale))
        .then((data) => {
          const filteredData = data.items.filter((d) => filterLatestUpdate(station.site_id, brand, classOfTradeCode(station), province, isOTRDealer, d));
          const updates = toLatestUpdates(filteredData);
          resolve(updates);
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all updates in last 7 days in Contentful format to a much more lean, flat and summarised structure
   * @author Kevin Parkinson
   * @param {number} daysAgo
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<LatestUpdate[]>}
  */
  async getAllUpdatesInLastNDays(daysAgo: number, locale: string): Promise<LatestUpdate[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CLatestUpdateWithFilters>(queryUpdatesInLastNDays(daysAgo, locale))
        .then((data) => {
          const filteredData = data.items.filter((d) => (!d.fields.specificBu || d.fields.specificBu.length === 0)); // don't include articles with specific a BU
          resolve(toLatestUpdates(filteredData));
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all updates in last 7 days in Contentful format to a much more lean, flat and summarised structure
   * @author Kevin Parkinson
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<LatestUpdate[]>}
  */
  async getAllUpdatesInDateRange(startDate: Date, endDate: Date, locale: string): Promise<LatestUpdate[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CLatestUpdateWithFilters>(queryUpdatesInDateRange(startDate, endDate, locale))
        .then((data) => {
          const filteredData = data.items.filter((d) => (!d.fields.specificBu || d.fields.specificBu.length === 0)); // don't include articles with specific a BU
          resolve(toLatestUpdates(filteredData));
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Finds out whether the given category exists in Contentful or not
   * @author Kevin Parkinson
   * @param {string} category
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<boolean]>}
  */
  async categoryExists(category: string, locale: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getArticleCategories(locale)
        .then((categories) => resolve(categories.includes(category)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Finds out whether the given tag exists in Contentful or not
   * @author Kevin Parkinson
   * @param {string} tag
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<boolean]>}
  */
  async tagExists(tag: string, locale: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const query = queryTag(tag, locale);
      rest.getEntries<{ sys, tag }>(query)
        .then((data) => resolve(data.items.length > 0))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all articles in a given category in Contentful in a much more lean, flat and summarised structure and filtered by brand, region and class of trade
   * @author Kevin Parkinson
   * @param {string} category
   * @param {GatewayStation} station
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]]>}
  */
  async getArticlesInCategory(category: string, station: GatewayStation, locale: string): Promise<ArticleSummary[]> {
    const { brand, province, is_otr_dealer: isOTRDealer } = station;
    return new Promise((resolve, reject) => {
      const query = queryArticlesByCategory(category, locale);
      rest.getEntries<CArticleSummaryWithFilters>(query)
        .then((data) => {
          const filteredData = data.items.filter((d) => filterArticleSummary(station.site_id, brand, classOfTradeCode(station), province, isOTRDealer, d));
          const articleSummaries = toArticleSummariesFiltered(filteredData);
          resolve(articleSummaries);
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all articles in a given category in Contentful in a much more lean, flat and summarised structure
   * @author Kevin Parkinson
   * @param {string} category
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]]>}
  */
  async getAllArticlesInCategory(category: string, locale: string): Promise<ArticleSummary[]> {
    return new Promise((resolve, reject) => {
      const query = queryArticlesByCategory(category, locale);
      rest.getEntries<CArticleSummaryWithFilters>(query)
        .then((data) => {
          const filteredData = data.items.filter((d) => (!d.fields.specificBu || d.fields.specificBu.length === 0)); // don't include articles with specific a BU
          resolve(toArticleSummariesFiltered(filteredData));
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all articles tagged with specific tag in Contentful in a much more lean, flat and summarised structure and filtered by brand, region and class of trade
   * @author Kevin Parkinson
   * @param {string} tag
   * @param {GatewayStation} station
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]]>}
  */
  async getArticlesTaggedWith(tag: string, station: GatewayStation, locale: string): Promise<ArticleSummary[]> {
    const { brand, province, is_otr_dealer: isOTRDealer } = station;
    return new Promise((resolve, reject) => {
      graphQL.query<any>(q(queryArticlesWithTagWithFilters(tag, locale)))
        .then((data) => {
          const generalTagsCollection = data.data.generalTagsCollection.items[0];
          if (generalTagsCollection) {
            const articles = generalTagsCollection.linkedFrom.stationPortalArticleCollection.items;
            const filteredData = articles.filter((d) => filterArticleSummaryTagged(station.site_id, brand, classOfTradeCode(station), province, isOTRDealer, d));
            const articleSummaries: ArticleSummary[] = filteredData.map((a) => taggedArticleToArticleSummary(a));
            resolve(articleSummaries);
          } else resolve([]);
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all articles tagged with specific tag in Contentful in a much more lean, flat and summarised structure
   * @author Kevin Parkinson
   * @param {string} tag
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]]>}
  */
  async getAllArticlesTaggedWith(tag: string, locale: string): Promise<ArticleSummary[]> {
    return new Promise((resolve, reject) => {
      graphQL.query<any>(q(queryArticlesWithTag(tag, locale)))
        .then((data) => {
          const generalTagsCollection = data.data.generalTagsCollection.items[0];
          if (generalTagsCollection) {
            const articles = generalTagsCollection.linkedFrom.stationPortalArticleCollection.items;
            const articleSummaries: ArticleSummary[] = articles
              .filter((d) => !d.specificBu)
              .map((a) => taggedArticleToArticleSummary(a));
            resolve(articleSummaries);
          } else resolve([]);
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all articles in a given category in Contentful in a much more lean, flat and summarised structure and filtered by brand, region and class of trade
   * @author Kevin Parkinson
   * @param {string} excludeID - the id of the article to exclude
   * @param {string} category
   * @param {GatewayStation} station
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]]>}
  */
  async getOtherArticlesInCategory(excludeID: string, category: Category, station: GatewayStation, locale: string): Promise<ArticleSummary[]> {
    const { brand, province, is_otr_dealer: isOTRDealer } = station;
    return new Promise((resolve, reject) => {
      let query;
      switch (category) {
        case Category.Announcements:
          query = queryOtherAnnouncements(excludeID, locale);
          break;
        case Category.CriticalUpdates:
          query = queryOtherCriticalUpdates(excludeID, locale);
          break;
        case Category.LatestUpdates:
          query = queryOtherUpdatesInLastNDays(excludeID, 15, locale);
          break;
        default:
          query = queryArticlesByCategory(category, locale);
      }
      rest.getEntries<CArticleSummaryWithFilters>(query)
        .then((data) => {
          const filteredData = data.items.filter((d) => d.sys.id !== excludeID && filterArticleSummary(station.site_id, brand, classOfTradeCode(station), province, isOTRDealer, d));
          const articleSummaries = toArticleSummariesFiltered(filteredData);
          resolve(articleSummaries);
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets a list of all articles in a given category in Contentful in a much more lean, flat and summarised structure
   * @author Kevin Parkinson
   * @param {string} excludeID
   * @param {string} category
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]]>}
  */
  async getAllOtherArticlesInCategory(excludeID: string, category: Category, locale: string): Promise<ArticleSummary[]> {
    return new Promise((resolve, reject) => {
      let query;
      switch (category) {
        case Category.Announcements:
          query = queryOtherAnnouncements(excludeID, locale);
          break;
        case Category.CriticalUpdates:
          query = queryOtherCriticalUpdates(excludeID, locale);
          break;
        case Category.LatestUpdates:
          query = queryOtherUpdatesInLastNDays(excludeID, 15, locale);
          break;
        default:
          query = queryArticlesByCategory(category, locale);
      }
      rest.getEntries<CArticleSummaryWithFilters>(query)
        .then((data) => {
          const filteredData = data.items.filter((d) => d.sys.id !== excludeID && (!d.fields.specificBu || d.fields.specificBu.length === 0));
          const articleSummaries = toArticleSummariesFiltered(filteredData);
          resolve(articleSummaries);
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Retrieves an article from Contentful
   * @author Kevin Parkinson
   * @param id {string}
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Article[]]>}
  */
  async getArticle(id: string, locale: string): Promise<Article> {
    return new Promise((resolve, reject) => {
      rest.getEntry<CArticle>(id, queryEntries(locale))
        .then((data) => resolve(toArticle(data)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Retrieves a list of articles from Contentful that hte user has favourited
   * @author Kevin Parkinson
   * @param {string[]} articleIds - the list of article ids favourited
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]]>}
  */
  async getFavouritedArticles(articleIds: string[], locale: string): Promise<ArticleSummary[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticleSummary>(queryFavouritedArticles(articleIds, locale))
        .then((data) => resolve(toArticleSummaries(data.items)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Retrieves a list of articles from Contentful that hte user has favourited
   * @author Kevin Parkinson
   * @param {string[]} articleIds - the list of article ids favourited
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Article[]]>}
  */
  async getFavouritedArticlesFull(articleIds: string[], locale: string): Promise<Article[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticle>(queryFavouritedArticles(articleIds, locale))
        .then((data) => resolve(toArticles(data)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Retrieves a list of planograms from Contentful that hte user has favourited
   * @author Kevin Parkinson
   * @param {string[]} articleIds
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<PlanogramSummary[]]>}
  */
  async getFavouritedPlanograms(articleIds: string[], locale: string): Promise<PlanogramSummary[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CPlanogramSummary>(queryFavouritedPlanograms(articleIds, locale))
        .then((data) => resolve(toPlanogramSummaries(data)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Retrieves a list of planograms from Contentful that hte user has favourited
   * @author Kevin Parkinson
   * @param {string[]} articleIds
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<PlanogramSummary[]]>}
  */
  async getFavouritedPlanogramsFull(articleIds: string[], locale: string): Promise<FavouritedPlanogram[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CPlanogram>(queryFavouritedPlanogramsFull(articleIds, locale))
        .then((data) => resolve(toFavouritedPlanograms(data)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  // BRANDS
  /**
   * Retrieves a brand from Contentful
   * @author Kevin Parkinson
   * @param {string} brandName
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Brand>}
  */
  async getBrand(brandName: string, locale: string): Promise<Brand> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CBrand>(queryBrandByName(brandName, locale))
        .then((data) => resolve(simplifyContentfulBrand(data.items[0])))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  // CATEGORIES
  /**
   * Retrieves a list of article categories from Contentful
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<string[]]>}
  */
  async getArticleCategories(locale: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CCategory>(queryCategories(locale))
        .then((data) => resolve(simplifyContentfulCategories(data)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Retrieves a list of planogram categories from Contentful
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<string[]]>}
  */
  async getPlanogramCategories(locale: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CPlanogramCategory>(queryPlanogramCategories(locale))
        .then((data) => resolve(data.items.map((pc) => (pc && pc.fields && pc.fields.planogramCategory ? pc.fields.planogramCategory : ''))))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  // SUBCATEGORIES
  /**
   * Retrieves a list of article subcategories from Contentful
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<string[]]>}
  */
  async getSubCategories(locale: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const query = querySubCategories(locale);
      rest.getEntries<CSubcategory>(query)
        .then((data) => resolve(simplifyContentfulSubcategories(data)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  // LINKS
  /**
   * Retrieves a list of links from Contentful filtered by region brand and class of trade
   * @author Kevin Parkinson
   * @param {string} brand
   * @param {string} classOfTrade
   * @param {string} region
   * @param {boolean} isOTRDealer
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Link[]]>}
  */
  async getLinks(brand: string, classOfTrade: string, region: string, isOTRDealer: boolean, locale: string): Promise<Link[]> {
    return new Promise((resolve, reject) => {
      graphQL.query<any>(q(queryLinks(locale)))
        .then((data) => {
          const links = data.data.generalLinkListCollection as CGeneralLinkListCollection;
          const filteredData = links.items.filter((d) => filterLink(brand, classOfTrade, region, isOTRDealer, d));
          resolve(toLinksWithFilters(filteredData));
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Retrieves a list of links from Contentful
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Link[]]>}
  */
  async getAllLinks(locale: string): Promise<Link[]> {
    return new Promise((resolve, reject) => {
      graphQL.query<any>(q(queryAllLinks(locale)))
        .then((data) => resolve(toLinks(data.data.generalLinkListCollection as CGeneralLinkListCollection)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Retrieves a list of links from Contentful
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Link[]]>}
  */
  async getExternalLinks(locale: string): Promise<Link[]> {
    return new Promise((resolve, reject) => {
      graphQL.query<any>(q(queryExternalLinks(locale)))
        .then((data) => resolve(toExternalLinks(data.data.generalListGroupsCollection as CGeneralListGroupsCollection)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  // Planograms
  /**
   * Retrieves a list of planograms in the given category from Contentful filtered by region brand and class of trade
   * @author Kevin Parkinson
   * @param {string} brand
   * @param {string} classOfTrade
   * @param {string} region
   * @param {boolean} isOTRDealer
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Planogram[]]>}
  */
  async getPlanogramsByCategory(category: string, brand: string, classOfTrade: string, region: string, isOTRDealer: boolean, locale: string): Promise<Planogram[]> {
    return new Promise((resolve, reject) => {
      graphQL.query<any>(q(queryPlanogramsByCategory(category, locale)))
        .then((data) => {
          const planogramCollection = data.data.stationPortalPlanogramCollection as CPlanogramCollection;
          const filteredData = planogramCollection.items.filter((d) => filterPlanogram(brand, classOfTrade, region, isOTRDealer, d));
          const planograms = toPlanogramsWithFilters(filteredData);
          resolve(planograms);
        })
        .catch((err) => {
          standardRejectedPromise(serviceName, reject, err);
        });
    });
  }

  /**
   * Retrieves a list of planograms in the given category from Contentful
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Planogram[]]>}
  */
  async getAllPlanogramsByCategory(category: string, locale: string): Promise<Planogram[]> {
    return new Promise((resolve, reject) => {
      graphQL.query<any>(q(queryPlanogramsByCategory(category, locale)))
        .then((data) => {
          const planogramCollection = data.data.stationPortalPlanogramCollection as CPlanogramCollection;
          const planograms = toPlanogramsWithFilters(planogramCollection.items);
          resolve(planograms);
        })
        .catch((err) => {
          standardRejectedPromise(serviceName, reject, err);
        });
    });
  }

  // Help
  /**
   * Retrieves a list of help topics for use in  user help email messaging
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<string[]]>}
  */
  async getHelpTopics(locale: string): Promise<string[]> {
    return new Promise((resolve) => {
      if (locale === appConfig.i18n.languages.i18nFrench) {
        resolve(helpTopics.fr);
      } else {
        resolve(helpTopics.en);
      }
    });
  }

  // Contact directory
  /**
   * Retrieves a list of contacts from Contentful
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<Contact[]]>}
   * @comments not currently implemented
  */
  async getContacts(locale: string): Promise<Contact[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<any>(queryByContentType(contact, locale))
        .then(() => {
          const contactList: Contact[] = [];
          resolve(contactList);
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  // FAQs
  /**
   * TODO: describe function
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<FAQ[]]>}
  */
  async getFAQs(locale: string): Promise<FAQ[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CFAQ>(queryFAQs(locale))
        .then((data) => {
          const faqList: FAQ[] = data.items.map((f) => ({
            question: f.fields.question,
            answer: f.fields.answer,
            category: f.fields.faqCategory ? f.fields.faqCategory.fields.faqCategory : ''
          }));
          resolve(faqList);
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  // Locales
  /**
   * TODO: describe function
   * @author Kevin Parkinson
   * @return {Promise<LanguageOption[]]>}
  */
  async getLocales(): Promise<LanguageOption[]> {
    return new Promise((resolve, reject) => {
      rest.getLocales()
        .then((data) => resolve(data.items.map((locale) => ({ code: locale.code, name: locale.name }))))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  // Search
  /**
   * TODO: describe function
   * @author Kevin Parkinson
   * @param {string} searchTerm
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]]>}

  */
  async searchAllArticles(searchTerm: string, locale: string): Promise<ArticleSummary[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticleSummary>(searchArticles(searchTerm, locale))
        .then((data) => {
          const filteredData = data.items.filter((d) => (!d.fields.specificBu || d.fields.specificBu.length === 0));
          resolve(toArticleSummaries(filteredData));
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  async searchAllArticlesBySearchTag(tag: string, locale: string): Promise<ArticleSummary[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticleSummary>(searchArticlesByTag(tag, locale))
        .then((data) => {
          const filteredData = data.items.filter((d) => (!d.fields.specificBu || d.fields.specificBu.length === 0));
          resolve(toArticleSummaries(filteredData));
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * TODO: describe function
   * @author Kevin Parkinson
   * @param {string} searchTerm
   * @param {GatewayStation} station
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]]>}
  */
  async searchArticlesWithFilters(searchTerm: string, station: GatewayStation, locale: string): Promise<ArticleSummary[]> {
    const { site_id: siteID, brand, province, is_otr_dealer: isOTRDealer } = station;
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticleSummaryWithFilters>(searchArticlesWithFilters(searchTerm, locale))
        .then((data) => {
          if (data.items.length === 0) {
            resolve([]);
          } else {
            const filteredData = data.items.filter((d) => filterArticleSummary(siteID, brand, classOfTradeCode(station), province, isOTRDealer, d));
            if (filteredData && filteredData.length !== 0) {
              resolve(toArticleSummariesFiltered(filteredData));
            } else {
              resolve([]);
            }
          }
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Allows the user to search planograms with a search term
   * @author Kevin Parkinson
   * @param {string} searchTerm
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]]>}

  */
  async searchAllPlanos(searchTerm: string, locale: string): Promise<PlanogramSummary[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CPlanogramSummary>(searchPlanograms(searchTerm, locale))
        .then((data) => resolve(toPlanogramSummaries(data)))
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Allows the user to search planograms with a search term filtered by their stations properties
   * @author Kevin Parkinson
   * @param {string} searchTerm
   * @param {string} brand
   * @param {string} classOfTrade
   * @param {string} region
   * @param {boolean} isOTRDealer
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<ArticleSummary[]]>}
  */
  async searchPlanosWithFilters(searchTerm: string, brand: string, classOfTrade: string, region: string, isOTRDealer: boolean, locale: string): Promise<PlanogramSummary[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CPlanogramSummaryWithFilters>(searchPlanogramsWithFilters(searchTerm, locale))
        .then((data) => {
          if (!data || !data.items || data.items.length === 0) {
            resolve([]);
          } else {
            const filteredData = data.items.filter((d) => filterPlanogramSummary(brand, classOfTrade, region, isOTRDealer, d));
            if (filteredData && filteredData.length !== 0) {
              resolve(toPlanogramSummariesFiltered(filteredData));
            } else {
              resolve([]);
            }
          }
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  // PROMOTIONAL PERIODS
  /**
   * Allows the user to search planograms with a search term filtered by their stations properties
   * @author Kevin Parkinson
   * @param {GatewayStation} station
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<CalendarEvent[]]>}
  */
  async getCalendarEventsWithFilters(station: GatewayStation, locale: string): Promise<BigCalendarEvent[]> {
    const { site_id: siteID, brand, province, is_otr_dealer: isOTRDealer } = station;
    return new Promise((resolve, reject) => {
      graphQL.query<any>(q(queryEventsWithFilters(locale)))
        .then((data) => {
          const promotionalPeriodsCollection = data.data.generalCalendarEntryCollection as CGeneralCalendarEntryCollection;
          const filteredData = promotionalPeriodsCollection.items.filter((d) => filterCalendarEvent(siteID, brand, classOfTradeCode(station), province, isOTRDealer, d.article));
          const events = articlesToCalendarEventsWithFilters(filteredData);
          resolve(events);
        })
        .catch((err) => {
          standardRejectedPromise(serviceName, reject, err);
        });
    });
  }

  /**
   * Allows the user to search planograms with a search term filtered by their stations properties
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<CalendarEvent[]]>}
  */
  async getAllCalendarEvents(locale: string): Promise<BigCalendarEvent[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CPromoPeriodArticle>(queryCalendarArticles(locale))
        .then((data) => {
          if (!data || !data.items || data.items.length === 0) {
            resolve([]);
          } else {
            const filteredData = data.items.filter((d) => d && d.fields && d.fields.article && !d.fields.article.fields.specificBu);
            resolve(articlesToCalendarEvents(filteredData));
          }
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Allows the user to search planograms with a search term filtered by their stations properties
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<CalendarEvent[]]>}
  */
  async getLatestHealthAndSafetyMoment(locale: string): Promise<HealthAndSafetyMoment> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CHSEMoment>(queryLatestHSEMoment(locale))
        .then((data) => {
          if (data.total === 0) {
            resolve(null);
          } else {
            const item = data.items[0];
            resolve(toHSEMoment(item));
          }
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Allows the user to search planograms with a search term filtered by their stations properties
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<CalendarEvent[]]>}
  */
  async getLatestOTRArticle(locale: string): Promise<Article> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticle>(queryLatestOTRArticle(locale))
        .then((data) => {
          if (data.total === 0) {
            resolve(null);
          } else {
            const item = data.items[0];
            resolve(toArticle(item));
          }
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Allows the user to search planograms with a search term filtered by their stations properties
   * @author Kevin Parkinson
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<CalendarEvent[]]>}
  */
  async getCarWashArticles(station: GatewayStation, locale: string): Promise<ArticleSummary[]> {
    const { site_id: siteID, brand, province, is_otr_dealer: isOTRDealer } = station;
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticleSummaryWithFilters>(queryCarWashArticles(locale))
        .then((data) => {
          if (!data || !data.items || data.items.length === 0) {
            resolve([]);
          } else {
            const filteredData = data.items.filter((d) => filterArticleSummary(siteID, brand, classOfTradeCode(station), province, isOTRDealer, d));
            if (filteredData && filteredData.length !== 0) {
              resolve(toArticleSummariesFiltered(filteredData));
            } else {
              resolve([]);
            }
          }
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Gets aall of the articles targeted for a specific site
   * @author Kevin Parkinson
   * @param {string} siteID - the specific site id
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<CalendarEvent[]]>}
  */
  async getSiteSpecficArticles(siteID: string, locale: string): Promise<ArticleSummary[]> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticleSummary>(querySiteSpecificArticles(siteID, locale))
        .then((data) => {
          if (!data || !data.items || data.items.length === 0) {
            resolve([]);
          } else {
            resolve(toArticleSummaries(data.items));
          }
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Searches Contentful for articles intended for the user's specific role
   * @author Kevin Parkinson
   * @param {UserRole} role - the Contentful locale to get content in
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<CalendarEvent[]]>}
  */
  async getInternalDocumentationByRole(role: UserRole, locale: string): Promise<ArticleSummary[]> {
    let query;
    switch (role) {
      case 'Admin':
        query = queryAdminArticles(locale);
        break;
      case 'TM':
      case 'ROM':
        query = queryTMROMArticles(locale);
        break;
      default:
        query = queryAdminArticles(locale);
    }
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticleSummary>(query)
        .then((data) => {
          if (!data || !data.items || data.items.length === 0) {
            resolve([]);
          } else {
            resolve(toArticleSummaries(data.items));
          }
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }

  /**
   * Allows the user to search planograms with a search term filtered by their stations properties
   * @author John Andaya
   * @param {string} locale - the Contentful locale to get content in
   * @return {Promise<CalendarEvent[]]>}
  */
  async getTermsAndConditionsArticle(locale: string): Promise<TermsAndConditions> {
    return new Promise((resolve, reject) => {
      rest.getEntries<CArticle>(queryTermsAndConditionsArticle(locale))
        .then((data) => {
          if (data.total === 0) {
            resolve(null);
          } else {
            const item = data.items[0];
            resolve(toArticle(item));
          }
        })
        .catch((err) => standardRejectedPromise(serviceName, reject, err));
    });
  }
}

export const contentfulService = new ContentfulService();
